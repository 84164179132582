import React from "react";
import EventHeader from "./EventHeader/EventHeader";
import EventTabs from "./EventTabs/EventTabs";
import EventTabsSecondary from "./EventTabsSecondary/EventTabsSecondary";

const EventHeaderWrapper = ({ match, playUrl }) => {
  //console.log("playUrl : " + playUrl);
  return (
    <div className="data-maintain-scroll-position-container">
      <section id="event-header">
        <div className="event-header-shadow"></div>
        <div className="event-header-wrapper is-normal">
          <EventHeader match={match} playUrl={playUrl} />
          {/* <EventTabs match={match} /> */}
          <EventTabsSecondary match={match} />
        </div>
      </section>
    </div>
  );
};

export default EventHeaderWrapper;
