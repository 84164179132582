import React, { useState, useEffect } from "react";
import eventHeaderImage from "../../../../assets/images/football-arena.jpg";
import EventHeaderBar from "./EventHeaderBar/EventHeaderBar";
import EventHeaderMatchinfo from "./EventHeaderMatchinfo/EventHeaderMatchinfo";
import EventHeaderGoalScorers from "./EventHeaderGoalScorers/EventHeaderGoalScorers";
import EventHeaderInPlayWidgets from "./EventHeaderInPlayWidgets/EventHeaderInPlayWidgets";
import EventLivestream from "./EventHeaderInPlayWidgets/EventLivestream/EventLivestream";
import LiveSteamOrMatchLive from "./EventHeaderMatchinfo/LiveSteamOrMatchLive/LiveSteamOrMatchLive";
import CryptoJS from "crypto-js";

const SECRET_KEY = process.env.REACT_APP_SECRETKEY_ENCRYPT;
const STREAM_URL = process.env.REACT_APP_STREAM_URL;
function encrypt(text) {
  const iv = CryptoJS.lib.WordArray.random(16); // สร้าง IV แบบสุ่ม
  const encrypted = CryptoJS.AES.encrypt(
    text,
    CryptoJS.enc.Utf8.parse(SECRET_KEY),
    { iv: iv }
  );
  const combined =
    iv.toString() + ":" + encrypted.ciphertext.toString(CryptoJS.enc.Hex);
  return combined;
}

function decrypt(text) {
  const textParts = text.split(":");
  const iv = CryptoJS.enc.Hex.parse(textParts.shift());
  const encryptedText = CryptoJS.enc.Hex.parse(textParts.join(":"));
  const encryptedData = { ciphertext: encryptedText, iv: iv };
  const decrypted = CryptoJS.AES.decrypt(
    encryptedData,
    CryptoJS.enc.Utf8.parse(SECRET_KEY),
    { iv: iv }
  );
  return CryptoJS.enc.Utf8.stringify(decrypted);
}

const EventHeader = ({ match, playUrl }) => {
  const [viewMode, setViewMode] = useState("overview");
  const [decryptedUrl, setDecryptedUrl] = useState("");
  const [isDecrypted, setDecrypted] = useState(true);
  const [encryptedFlv, setEncryptedFlv] = useState("");
  const [encryptedM3u8, setEncryptedM3u8] = useState("");
  const [encryptedRtc, setEncryptedRtc] = useState("");
  const [flvUrl, setFlvUrl] = useState("");
  const [m3u8Url, setM3u8Url] = useState("");
  const [rtcUrl, setRtcUrl] = useState("");

  useEffect(() => {
    if (playUrl) {
      const decrypted = decrypt(playUrl);
      setDecryptedUrl(decrypted);
    }
  }, [playUrl]);

  useEffect(() => {
    if (decryptedUrl && isDecrypted) {
      try {
        const jsonData = JSON.parse(decryptedUrl);
        setFlvUrl(jsonData?.flvUrl);
        setM3u8Url(jsonData?.m3u8Url);
        setRtcUrl(jsonData?.rtcUrl);
        setDecrypted(false);
      } catch (error) {
        console.error("Error parsing decryptedUrl:", error);
      }
    }
  }, [decryptedUrl, isDecrypted]);

  useEffect(() => {
    if (flvUrl) {
      if (localStorage.getItem("liveMode") !== "iOS_MODE") {
        // เงื่อนไขที่ไม่ใช่ iOS_MODE
        const encrypted = encrypt(flvUrl);
        setEncryptedFlv(`${STREAM_URL}/api/match/liveStreamV2?l=${encrypted}`);
      } else {
        // เงื่อนไขสำหรับ iOS_MODE
        const matchId = flvUrl.match(/\/1-(\d+)-/); // หาข้อความที่ตรงกับรูปแบบ
        if (matchId && matchId[1]) {
          setEncryptedFlv(`${STREAM_URL}/livestream/sd-1-${matchId[1]}.m3u8`);
        }
      }
    }
    if (m3u8Url) {
      const encrypted = encrypt(m3u8Url);
      setEncryptedM3u8(`${STREAM_URL}/api/match/liveStreamV2?l=${encrypted}`);
    }
    if (rtcUrl) {
      const encrypted = encrypt(rtcUrl);
      setEncryptedRtc(`${STREAM_URL}/api/match/liveStreamV2?l=${encrypted}`);
    }
  }, [flvUrl, m3u8Url, rtcUrl]);

  return (
    <div
      className={`event-header ${
        viewMode === "liveStream"
          ? "event-header--liveStream"
          : "event-header--inplay"
      }`}
    >
      {viewMode === "overview" && (
        <>
          {/* eslint-disable-next-line */}
          <img
            fetchpriority="high"
            className="discoverable-bg-img"
            width="420"
            height="163"
            src={eventHeaderImage}
          />
          <EventHeaderBar match={match} />
          <EventHeaderMatchinfo match={match} />
          <EventHeaderGoalScorers match={match} />
          <LiveSteamOrMatchLive match={match} onChangeViewMode={setViewMode} />
        </>
      )}
      {viewMode === "liveStream" && (
        <EventLivestream
          match={match}
          player1={encryptedFlv}
          player2={encryptedM3u8}
          player3={encryptedRtc}
        />
      )}
      <EventHeaderInPlayWidgets match={match} onChangeViewMode={setViewMode} />
    </div>
  );
};

export default EventHeader;
