// EventHeaderInPlayWidgetsNav.js
import React from "react";
import "./EventHeaderInPlayWidgetsNav.css";

const EventHeaderInPlayWidgetsNav = ({ onChangeViewMode }) => {
  return (
    <div className="event-header-in-play-widgets-nav">
      <div className="event-header-in-play-widgets-nav__inner">
        <div className="event-header-in-play-widgets-nav__buttons">
          <button
            type="button"
            className="active"
            onClick={() => onChangeViewMode("overview")}
          >
            ภาพรวม
          </button>
          {/* <button type="button" className="" style={{ display: "flex" }}>
            แชท
          </button>
          <button type="button" className="" style={{ display: "flex" }}>
            เฮ็ด-ทู-เฮ็ด
          </button> */}
          {/* <button
            type="button"
            className=""
            onClick={() => onChangeViewMode("liveStream")}
          >
            <i className="youtube icon #b9b9b9"></i>
            ดูบอลสด
          </button> */}
        </div>
        <div className="event-header-in-play-widgets-nav__switch">
          <div className="toggle toggle--primary">
            <label className="toggle__inner">
              <input type="checkbox" className="checked" />
              <span className="toggle__handle-container">
                <span className="toggle__handle"></span>
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventHeaderInPlayWidgetsNav;
