// H2H.js
import React from "react";
import "./H2H.css";
import teamsDefaultImage from "../../../../assets/images/TeamsDefault.webp";

const H2H = ({ match }) => {
  return (
    <div
      className="event-section event-section--h2h"
      id="head-to-head"
      style={{ display: "none" }}
    >
      <div className="event-section__head">
        <div>
          <div>{/* <!----> */}</div>
          <div>
            <h3 className="event-section__title">เฮ็ด-ทู-เฮ็ด</h3>
            <div className="event-section__meta">Last 6 years</div>
          </div>
          <div>
            <div>{/* <!----> */}</div>
          </div>
        </div>
      </div>
      <div className="event-h2h">
        <div className="h2h-graph">
          <a
            href={`/football/teams/${match.Team_Match_home_team_idToTeam.id}`}
            className="team-link h2h-graph__team"
            title={
              match.Team_Match_home_team_idToTeam?.Language?.name_th ||
              match.Team_Match_home_team_idToTeam?.name
            }
          >
            <img
              alt={
                match.Team_Match_home_team_idToTeam?.Language?.name_th ||
                match.Team_Match_home_team_idToTeam?.name
              }
              className=""
              src={
                match.Team_Match_home_team_idToTeam?.logo_original ||
                teamsDefaultImage
              }
            />
            {/* <span>Shot Name</span> */}
            <div>{/* <!----> */}</div>
          </a>
          <div className="h2h-graph__stats-wrapper">
            <ul className="h2h-graph__stats">
              <li className="h2h-graph__score">
                <strong>6</strong>
                <span>Wins</span>
              </li>
              <li className="h2h-graph__score">
                <strong>4</strong>
                <span>Draws</span>
              </li>
              <li className="h2h-graph__score">
                <strong>3</strong>
                <span>Wins</span>
              </li>
            </ul>
            <div className="h2h-graph__progressbar">
              <div className="progressbar-container">
                <div className="progressbar-small">
                  <div style={{ width: "46.1538%" }}></div>
                </div>
              </div>
              <div className="progressbar-container">
                <div className="progressbar-small progressbar--left">
                  <div style={{ width: "23.0769%" }}></div>
                </div>
              </div>
            </div>
            <p className="h2h-graph__total">13 played</p>
          </div>
          <a
            href={`/football/teams/${match.Team_Match_away_team_idToTeam.id}`}
            title={
              match.Team_Match_away_team_idToTeam?.Language?.name_th ||
              match.Team_Match_away_team_idToTeam?.name
            }
            className="team-link h2h-graph__team"
          >
            <img
              alt={
                match.Team_Match_away_team_idToTeam?.Language?.name_th ||
                match.Team_Match_away_team_idToTeam?.name
              }
              className=""
              src={
                match.Team_Match_away_team_idToTeam?.logo_original ||
                teamsDefaultImage
              }
            />
            {/* <span>WOL</span> */}
            <div>{/* <!----> */}</div>
          </a>
        </div>
        <div className="h2h-results-primary">
          <div>{/* <!----> */}</div>
          <a
            href="/football/wolverhampton-wanderers-manchester-united-49469?m=6612354"
            title="Manchester United Wolverhampton Wanderers"
            className="h2h-results-primary__item"
          >
            <span className="h2h-results-primary__match-date">
              13 May 2023 -{" "}
              {match.Competition?.Language?.name_th || match.Competition?.name}
            </span>
            <div>
              <div className="h2h-results-primary__team">
                <span className="is-winning">
                  {match.Team_Match_home_team_idToTeam?.Language?.name_th ||
                    match.Team_Match_home_team_idToTeam?.name}
                </span>
              </div>
              <div className="h2h-results-primary__score-desktop">
                <span>2 - 0</span>
              </div>
              <div className="h2h-results-primary__score-mobile">
                <div className="is-winning">
                  <span>2</span>
                </div>
                <div className="">
                  <span>0</span>
                </div>
              </div>
              <div className="h2h-results-primary__team">
                <span className="">
                  {match.Team_Match_away_team_idToTeam?.Language?.name_th ||
                    match.Team_Match_away_team_idToTeam?.name}
                </span>
              </div>
            </div>
          </a>
        </div>
        <button
          type="button"
          className="h2h-results-pagination btn-expand color-primary"
        >
          Show more
          <i className="icon-down down icon"></i>
        </button>
      </div>
    </div>
  );
};

export default H2H;
