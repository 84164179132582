import React, { useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import flvjs from "flv.js";

function FlvPlayer({ src, poster }) {
  const videoRef = useRef(null);

  useEffect(() => {
    // ตรวจสอบว่า src ไม่ว่าง
    if (!src) {
      // หรือคุณสามารถดำเนินการอื่นๆ ตามความเหมาะสม
      return;
    }
    if (flvjs.isSupported()) {
      const player = videojs(videoRef.current, {
        controls: true,
        isLive: true,
        liveui: true,
        volume: 1,
        autoplay: true,
        preload: "auto",
        fluid: true,
        nativeControlsForTouch: false,
        language: "th",
        liveTracker: true,
        responsive: true,
        seeking: false,
        notSupportedMessage: "ไลฟ์สตรีมไม่พร้อมแสดงในขณะนี้",
      });

      const flvPlayer = flvjs.createPlayer({
        type: "flv",
        url: src,
      });

      flvPlayer.attachMediaElement(videoRef.current);
      flvPlayer.load();

      // ควบคุม flvPlayer ผ่าน player ของ video.js
      player.on("play", () => flvPlayer.play());
      player.on("pause", () => flvPlayer.pause());

      return () => {
        if (player) {
          player.dispose();
        }
        if (flvPlayer) {
          flvPlayer.destroy();
        }
      };
    }
  }, [src]);

  return (
    <div data-vjs-player>
      <video
        ref={videoRef}
        className="vjs-matrix video-js vjs-user-active"
        playsInline
        poster={poster}
      ></video>
    </div>
  );
}

export default FlvPlayer;
