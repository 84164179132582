import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./MatchListItem.css";
import teamsDefaultImage from "../../../assets/images/TeamsDefault.webp";
import { Shirt } from "../../../assets/images/icons";
import MatchStats from "./MatchStats/MatchStats";

function MatchListItem({
  matchTime,
  homeTeam,
  awayTeam,
  homeScores,
  awayScores,
  liveStream,
  matchId,
  matchStatus,
  matchKickOff,
  shouldHighlight,
  matchStats,
  matchIncidents,
  isLive,
  matchLineup,
}) {
  const [homeTeamData] = homeTeam;
  const [awayTeamData] = awayTeam;
  const [homeScoresData] = homeScores;
  const [awayScoresData] = awayScores;
  const [liveStreamData] = liveStream;
  const [matchStatusData] = matchStatus;
  const matchTimeTimeStamp = matchTime;
  const matchTimeDateTime = new Date(matchTimeTimeStamp);
  const [hour, minute] = matchTimeDateTime.toLocaleTimeString().split(":");
  const timeString = `${hour}:${minute}`;
  const homeTeamNameFormatted = homeTeamData?.name.replace(/ /g, "-");
  const awayTeamNameFormatted = awayTeamData?.name.replace(/ /g, "-");
  const hrefValue = `/football/${homeTeamNameFormatted}-${awayTeamNameFormatted}/${matchId}`;
  const [matchStatsData] = matchStats;
  const [matchIncidentsData] = matchIncidents;
  const matchLineupData = matchLineup;

  const [highlight, setHighlight] = useState(false);

  useEffect(() => {
    if (shouldHighlight) {
      setHighlight(true);
      const timer = setTimeout(() => {
        setHighlight(false);
      }, 10000); // 10 วินาที
      return () => clearTimeout(timer);
    }
  }, [shouldHighlight]);

  const currentTimeStamp = new Date().getTime();
  const matchKickOffTimeStamp = new Date(matchKickOff).getTime();
  const [blink, setBlink] = useState(true);
  let displayTime = timeString;
  let statusClass = "match-status";
  let scoreClass = "match-score__team";
  let matchTeamsClass = "match-teams";
  let matchScoreClass = "match-game-score";
  let homeTeamClass = "match-team";
  let awayTeamClass = "match-team";
  let homeScoreClass = "match-set__team";
  let awayScoreClass = "match-set__team";

  if (matchStatusData === 8) {
    // ตรวจสอบว่าการแข่งขันเสร็จสิ้นหรือไม่
    if (homeScoresData[0] > awayScoresData[0]) {
      matchTeamsClass += " match-teams--highlight-winner";
      matchScoreClass += " match-teams--highlight-winner";
      homeTeamClass += " match-team--winner";
      awayScoreClass += " match-score__team--away muted";
    } else if (homeScoresData[0] < awayScoresData[0]) {
      matchTeamsClass += " match-teams--highlight-winner";
      matchScoreClass += " match-teams--highlight-winner";
      awayTeamClass += " match-team--winner";
      homeScoreClass += " match-score__team--home muted";
    }
  }
  switch (matchStatusData) {
    case 1:
      displayTime = timeString;
      statusClass += " match-status--not-started";
      scoreClass += " match-score--not-started";
      break;
    case 2:
      const minutesPassedFirstHalf = Math.floor(
        (currentTimeStamp - matchKickOffTimeStamp) / (60 * 1000) + 1
      );
      if (minutesPassedFirstHalf > 45) {
        const extraMinutesFirstHalf = minutesPassedFirstHalf - 45;
        displayTime = `45+${extraMinutesFirstHalf}'`;
      } else {
        displayTime = `${minutesPassedFirstHalf}'`;
      }
      statusClass += ` match-status--inplay ${
        shouldHighlight ? "highlight" : ""
      }`;
      scoreClass += ` match-score--inplay ${
        shouldHighlight ? "highlight" : ""
      }`;
      break;

    case 3:
      displayTime = "พักครึ่ง";
      statusClass += ` match-status--inplay ${
        shouldHighlight ? "highlight" : ""
      }`;
      scoreClass += ` match-score--inplay ${
        shouldHighlight ? "highlight" : ""
      }`;
      break;

    case 4:
      const minutesPassedSecondHalf = Math.floor(
        (currentTimeStamp - matchKickOffTimeStamp) / (60 * 1000) + 46
      );
      if (minutesPassedSecondHalf > 90) {
        const extraMinutesSecondHalf = minutesPassedSecondHalf - 90;
        displayTime = `90+${extraMinutesSecondHalf}'`;
      } else {
        displayTime = `${minutesPassedSecondHalf}'`;
      }
      statusClass += ` match-status--inplay ${
        shouldHighlight ? "highlight" : ""
      }`;
      scoreClass += ` match-score--inplay ${
        shouldHighlight ? "highlight" : ""
      }`;
      break;

    case 5:
      displayTime = "ต่อเวลาพิเศษ";
      statusClass += ` match-status--inplay ${
        shouldHighlight ? "highlight" : ""
      }`;
      scoreClass += ` match-score--inplay ${
        shouldHighlight ? "highlight" : ""
      }`;
      break;
    case 7:
      displayTime = "ดวลจุดโทษ";
      statusClass += ` match-status--inplay ${
        shouldHighlight ? "highlight" : ""
      }`;
      scoreClass += ` match-score--inplay ${
        shouldHighlight ? "highlight" : ""
      }`;
      break;
    case 8:
      displayTime = "เสร็จสิ้น";
      statusClass += " match-status--end";
      scoreClass += " match-score--end";
      break;
    case 9:
      displayTime = "ล่าช้า";
      statusClass += " match-status--wait";
      scoreClass += " match-score--wait";
      break;
    case 10:
      displayTime = "ขัดจังหวะ";
      statusClass += " match-status--special";
      scoreClass += " match-score--special";
      break;
    case 11:
      displayTime = "ตัดครึ่ง";
      statusClass += " match-status--special";
      scoreClass += " match-score--special";
      break;
    case 12:
      displayTime = "ยกเลิก";
      statusClass += " match-status--special";
      scoreClass += " match-score--special";
      break;
    case 13:
      displayTime = "รอการพิจารณา";
      statusClass += " match-status--wait";
      scoreClass += " match-score--wait";
      break;
    default:
      displayTime = timeString;
  }

  const [displayedTime, setDisplayedTime] = useState(displayTime);

  useEffect(() => {
    const timeUpdateInterval = setInterval(() => {
      setBlink((prevBlink) => !prevBlink);
      let newTime;
      switch (matchStatusData) {
        case 1:
          newTime = timeString;
          statusClass += " match-status--not-started";
          scoreClass += " match-score--not-started";
          break;
        case 2:
          const minutesPassedFirstHalf = Math.floor(
            (Date.now() - matchKickOffTimeStamp) / (60 * 1000)
          );
          if (minutesPassedFirstHalf > 45) {
            const extraMinutesFirstHalf = minutesPassedFirstHalf - 45;
            newTime = `45+${extraMinutesFirstHalf}'`;
          } else {
            newTime = `${minutesPassedFirstHalf}'`;
          }
          statusClass += ` match-status--inplay ${
            shouldHighlight ? "highlight" : ""
          }`;
          scoreClass += ` match-score--inplay ${
            shouldHighlight ? "highlight" : ""
          }`;
          break;
        case 3:
          newTime = "พักครึ่ง";
          statusClass += ` match-status--inplay ${
            shouldHighlight ? "highlight" : ""
          }`;
          scoreClass += ` match-score--inplay ${
            shouldHighlight ? "highlight" : ""
          }`;
          break;
        case 4:
          const minutesPassedSecondHalf = Math.floor(
            (Date.now() - matchKickOffTimeStamp) / (60 * 1000) + 45
          );
          if (minutesPassedSecondHalf > 90) {
            const extraMinutesSecondHalf = minutesPassedSecondHalf - 90;
            newTime = `90+${extraMinutesSecondHalf}'`;
          } else {
            newTime = `${minutesPassedSecondHalf}'`;
          }
          statusClass += ` match-status--inplay ${
            shouldHighlight ? "highlight" : ""
          }`;
          scoreClass += ` match-score--inplay ${
            shouldHighlight ? "highlight" : ""
          }`;
          break;
        case 5:
          newTime = "ต่อเวลาพิเศษ";
          statusClass += ` match-status--inplay ${
            shouldHighlight ? "highlight" : ""
          }`;
          scoreClass += ` match-score--inplay ${
            shouldHighlight ? "highlight" : ""
          }`;
          break;
        case 7:
          newTime = "ดวลจุดโทษ";
          statusClass += ` match-status--inplay ${
            shouldHighlight ? "highlight" : ""
          }`;
          scoreClass += ` match-score--inplay ${
            shouldHighlight ? "highlight" : ""
          }`;
          break;
        case 8:
          newTime = "เสร็จสิ้น";
          statusClass += " match-status--end";
          scoreClass += " match-score--end";
          break;
        case 9:
          newTime = "ล่าช้า";
          statusClass += " match-status--wait";
          scoreClass += " match-score--wait";
          break;
        case 10:
          newTime = "ขัดจังหวะ";
          statusClass += " match-status--special";
          scoreClass += " match-score--special";
          break;
        case 11:
          newTime = "ตัดครึ่ง";
          statusClass += " match-status--special";
          scoreClass += " match-score--special";
          break;
        case 12:
          newTime = "ยกเลิก";
          statusClass += " match-status--special";
          scoreClass += " match-score--special";
          break;
        case 13:
          newTime = "รอการพิจารณา";
          statusClass += " match-status--wait";
          scoreClass += " match-score--wait";
          break;
        default:
          newTime = timeString;
      }
      setDisplayedTime(newTime);
    }, 1000);

    // ทำคืนค่าเมื่อ component ถูก unmount
    return () => {
      clearInterval(timeUpdateInterval);
    };
  }, [matchStatusData, matchKickOffTimeStamp, timeString]);

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      id={matchId}
      className={`match-list-item ${shouldHighlight ? "highlight" : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={"match"}>
        <Link
          to={hrefValue}
          // title={`${homeTeamData?.name} - ${awayTeamData?.name}`}
          className="match-url match-url--flex"
        >
          <div className="match-favourite-btn-wrapper">
            <button className="match-favourite-btn">
              <i className="match-favourite-btn__icon star outline icon"></i>
            </button>
          </div>
          <div className="show-match-stats">
            {isLive && isHovered && (
              <div className="match-stats">
                <MatchStats
                  homeTeamLogo={homeTeamData?.logo_original}
                  homeTeamName={homeTeamData?.name}
                  awayTeamLogo={awayTeamData?.logo_original}
                  awayTeamName={awayTeamData?.name}
                  incidents={matchIncidentsData}
                  stats={matchStatsData}
                />
              </div>
            )}
            <div className="match-state">
              <div className="match-state-inner">
                <div className={statusClass}>
                  {blink ? displayedTime : displayedTime.replace("'", " ")}
                  <span className="blink">&nbsp;</span>
                </div>
              </div>
              <div className="match-state-outer"></div>
            </div>
            <div className={matchTeamsClass}>
              <div className={homeTeamClass}>
                <img
                  alt={homeTeamData?.name}
                  width="20"
                  height="20"
                  className="logo-team match-team__logo u-circle image-size-sm lazyLoad isLoaded"
                  src={homeTeamData?.logo_original || teamsDefaultImage}
                />
                <div
                  className={`match-team__name ${
                    shouldHighlight ? "highlight" : ""
                  }`}
                >
                  {homeTeamData?.Language?.name_th || homeTeamData?.name}
                </div>
                <div className="match-team__widgets">
                  {Array(homeScoresData[2])
                    .fill()
                    .map((_, index) => (
                      <div key={index} className="event-red-card-indicator">
                        <span className="event-red-card-indicator__icon"></span>
                      </div>
                    ))}
                </div>
              </div>
              <div className={awayTeamClass}>
                <img
                  alt={awayTeamData?.name}
                  width="20"
                  height="20"
                  className="logo-team match-team__logo u-circle image-size-sm lazyLoad isLoaded"
                  src={awayTeamData?.logo_original || teamsDefaultImage}
                />
                <div
                  className={`match-team__name ${
                    shouldHighlight ? "highlight" : ""
                  }`}
                >
                  {awayTeamData?.name}
                </div>
                <div className="match-team__widgets">
                  {Array(awayScoresData[2])
                    .fill()
                    .map((_, index) => (
                      <div key={index} className="event-red-card-indicator">
                        <span className="event-red-card-indicator__icon"></span>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <div className={scoreClass}>
              <div className="match-set match-set--current">
                <div className={homeScoreClass}>
                  <div className="match-score-result">
                    <span className="match-score-result__score">
                      {homeScoresData[0]}
                    </span>
                  </div>
                </div>
                <div className={awayScoreClass}>
                  <div className="match-score-result">
                    <span className="match-score-result__score">
                      {awayScoresData[0]}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={scoreClass}>
              <div className="match-set match-set--current-HalfTime">
                <div className={homeScoreClass}>
                  <div className="match-score-result">
                    <span className="match-score-result__score">
                      ({homeScoresData[1]})
                    </span>
                  </div>
                </div>
                <div className={awayScoreClass}>
                  <div className="match-score-result">
                    <span className="match-score-result__score">
                      ({awayScoresData[1]})
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {(matchStatusData === 2 ||
            matchStatusData === 3 ||
            matchStatusData === 4 ||
            matchStatusData === 5 ||
            matchStatusData === 7) && (
            <div className="match-live-lineup">
              {matchLineupData === 1 ? (
                <Shirt />
              ) : (
                <span class="icon-setting"></span>
              )}
            </div>
          )}
          {(matchStatusData === 1 ||
            matchStatusData === 2 ||
            matchStatusData === 3 ||
            matchStatusData === 4 ||
            matchStatusData === 5 ||
            matchStatusData === 7) && (
            <div className="match-live-or-lineup">
              <span className="icon-setting">
                {liveStreamData?.status === null && " "}
                {liveStreamData && matchStatusData === 1 && (
                  <i className="play circle icon match-comming"></i>
                )}
                {liveStreamData &&
                  (matchStatusData === 2 ||
                    matchStatusData === 3 ||
                    matchStatusData === 4 ||
                    matchStatusData === 5 ||
                    matchStatusData === 7) && (
                    <i className="play circle icon match-live"></i>
                  )}
              </span>
            </div>
          )}
        </Link>
      </div>
    </div>
  );
}

export default MatchListItem;
