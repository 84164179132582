// \scorekub_frontend\src\redux\reducers\liveStreamReducer.js

import { actionTypes } from "../actions";

const initialState = {
  matchLiveStream: null,
  error: null,
};

const liveStreamReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MATCH_LIVE_STREAM:
      return {
        ...state,
        matchLiveStream: action.payload,
        error: null,
      };
    case actionTypes.FETCH_MATCH_LIVE_STREAM_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default liveStreamReducer;
