// EventHeaderLiveStats.js
import React from "react";
import "./EventHeaderLiveStats.css";
import Net from "../../../../../../assets/images/Net.webp";
import yellowcard from "../../../../../../assets/images/yellowcard.png";
import redcard from "../../../../../../assets/images/redcard.png";
import corner from "../../../../../../assets/images/corner.png";

const EventHeaderLiveStats = ({ match }) => {
  const possessionStat =
    Array.isArray(match?.stats) &&
    match?.stats.find((stat) => stat.type === 25);
  let homePossession = possessionStat ? possessionStat.home : 0;
  let awayPossession = possessionStat ? possessionStat.away : 0;

  if (homePossession === 0 && awayPossession === 0) {
    homePossession = 50;
    awayPossession = 50;
  }

  const getStatValue = (type, team = "home") => {
    const statItem =
      Array.isArray(match?.stats) &&
      match?.stats.find((stat) => stat.type === type);
    return statItem ? statItem[team] : 0;
  };

  const shotsOnTargetHome = getStatValue(21, "home");
  const shotsOnTargetAway = getStatValue(21, "away");

  const type22Home = getStatValue(22, "home");
  const type22Away = getStatValue(22, "away");

  const shotsHome = type22Home + shotsOnTargetHome;
  const shotsAway = type22Away + shotsOnTargetAway;

  const yellowCardHome = getStatValue(3, "home");
  const yellowCardAway = getStatValue(3, "away");
  const redCardHome = getStatValue(4, "home");
  const redCardAway = getStatValue(4, "away");

  return (
    <div className="event-header-live-stats event-header-live-stats--football">
      <div>
        <section className="event-header-live-stats__section">
          <div className="event-stats-football-shots">
            <div className="event-stats-football-shots__label">
              การยิงเข้ากรอบ / นอกกรอบ
            </div>
            <div className="event-stats-football-shots__content">
              <div>
                <span>{type22Home}</span>
                <img alt="Net Icon" src={Net} className="lazyLoad isLoaded" />
                <span>{shotsOnTargetHome}</span>
              </div>
              <div>
                <span>{type22Away}</span>
                <img alt="Net Icon" src={Net} className="lazyLoad isLoaded" />
                <span>{shotsOnTargetAway}</span>
              </div>
            </div>
          </div>
        </section>
        <section className="event-header-live-stats__section">
          <div className="event-live-stats-icons-football">
            <div className="event-stats-icon" label="Corners">
              <span>{match.home_scores[4]}</span>
              <div>
                <img
                  alt="corner-kicks"
                  src={corner}
                  className="lazyLoad isLoaded"
                />
              </div>
              <span>{match.away_scores[4]}</span>
            </div>
            <div className="event-stats-icon" label="Yellow cards">
              <span>{match.home_scores[3]}</span>
              <div>
                {/* <i className="square icon yellow"></i> */}
                <img
                  alt="corner-kicks"
                  src={yellowcard}
                  className="lazyLoad isLoaded"
                />
              </div>
              <span>{match.away_scores[3]}</span>
            </div>
            <div className="event-stats-icon" label="Red cards">
              <span>{match.home_scores[2]}</span>
              <div>
                {/* <i className="square icon red"></i> */}
                <img
                  alt="corner-kicks"
                  src={redcard}
                  className="lazyLoad isLoaded"
                />
              </div>
              <span>{match.away_scores[2]}</span>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default EventHeaderLiveStats;
