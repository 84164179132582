import React, { useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-flvjs";

function VideoPlayer({ src, poster }) {
  const videoRef = useRef(null);

  useEffect(() => {
    // ตรวจสอบว่า src ไม่ว่าง
    if (!src) {
      // หรือคุณสามารถดำเนินการอื่นๆ ตามความเหมาะสม
      return;
    }

    // สร้างตัวเล่นวิดีโอ
    const player = videojs(videoRef.current, {
      controls: true,
      isLive: true,
      liveui: true,
      volume: 1,
      autoplay: true,
      preload: "auto",
      fluid: true,
      liveTracker: true,
      seeking: false,
      responsive: true,
      nativeControlsForTouch: false,
      language: "th",
      notSupportedMessage: "ไลฟ์สตรีมไม่พร้อมแสดงในขณะนี้",
      sources: [
        {
          src,
          type: "application/x-mpegURL",
        },
      ],
    });

    return () => {
      if (player) {
        player.dispose();
      }
    };
  }, [src]);

  return (
    <div data-vjs-player>
      <video
        ref={videoRef}
        className="vjs-matrix video-js vjs-user-active"
        playsInline
        poster={poster}
      ></video>
    </div>
  );
}

export default VideoPlayer;
