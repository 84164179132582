import React from "react";
import H2H from "./H2H/H2H";
import Lineups from "./Lineups/Lineups";
import MatchInfo from "./MatchInfo/MatchInfo";

const ContentWrapper = ({ match }) => {
  return (
    <div className="content content--wrapper">
      <H2H match={match} />
      <Lineups match={match} />
      <MatchInfo match={match} />
    </div>
  );
};

export default ContentWrapper;
