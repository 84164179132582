import React, { useState } from "react";
import "./Popup.css";
function Popup() {
  const [isPopupOpen, setIsPopupOpen] = useState(true);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  return (
    <div>
      {isPopupOpen && (
        <div className="popup">
          {/* <span className="close" onClick={togglePopup}>
            &times;
          </span> */}
          <div className="popup-content">
            <h3>⚠️ แจ้งเตือนสำคัญ: ระวังเว็บไซต์ปลอม ⚠️</h3>
            <p>เรียนผู้ใช้งานทุกท่าน,</p>
            <p>
              เราขอเน้นย้ำว่าเว็บไซต์อย่างเป็นทางการของเราคือ{" "}
              <strong>
                <a target="_new" href="https://www.scorekub.com">
                  ScoreKub.com
                </a>
              </strong>{" "}
              - แหล่งข้อมูลรายงานผลฟุตบอลที่คุณไว้วางใจได้.
              เราได้รับแจ้งว่ามีเว็บไซต์อื่นที่ชื่อ{" "}
              <strong>ScoreKub .net</strong>,
              ซึ่งอาจสร้างความสับสนและแอบอ้างเป็นเว็บไซต์ของเรา.
            </p>
            <p>
              <strong>โปรดระมัดระวัง</strong>: เว็บไซต์{" "}
              <strong>ScoreKub .net</strong>{" "}
              ไม่มีส่วนเกี่ยวข้องกับเราและอาจมีวัตถุประสงค์ในการให้บริการที่ไม่ตรงกับเว็บไซต์ของเรา.
              เราไม่รับผิดชอบต่อการใช้งานหรือข้อมูลที่มาจากเว็บไซต์ดังกล่าว.
            </p>
            <p>
              <strong>การใช้งานอย่างปลอดภัย</strong>: กรุณาตรวจสอบ URL
              ในแถบที่อยู่เว็บไซต์ของคุณให้ถูกต้องและใช้เฉพาะเว็บไซต์อย่างเป็นทางการของเรา.
              หากคุณมีข้อสงสัยหรือต้องการการช่วยเหลือ, กรุณาติดต่อเราที่{" "}
              <a target="_new" href="mailto:contact@scorekub.com">
                contact@scorekub.com
              </a>
              .
            </p>
            <p>ขอบคุณที่ใช้บริการ ScoreKub.com และรักษาความปลอดภัยออนไลน์.</p>
            <p>
              ด้วยความเคารพ,
              <br />
              ทีมงาน ScoreKub.com
            </p>
            <div className="popup-button-container">
              <button onClick={togglePopup} className="button-close">
                &times; ปิดแจ้งเตือน
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Popup;
