import React from "react";
import "./LiveSteamOrMatchLive.css";

const LiveSteamOrMatchLive = ({ match, onChangeViewMode }) => {
  if (!match.liveStream) {
    return null; // สามารถคืนค่าอื่นๆ ที่คุณต้องการแสดงในกรณีนี้
  }
  const { status } = match.liveStream;

  // เงื่อนไขที่ 1
  if (status === 66 && status === 77) {
    return (
      <div
        className="liveBox flex2"
        onClick={() => onChangeViewMode("liveStream")}
      >
        <button className="liveSream flex">
          <div className="myIcon van-image van-image--round">
            {/* eslint-disable-next-line */}
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAUVBMVEUAAAD2Ozv3Ojr2PDz4Pz/2Pj72PT33Pj73Pj74Pj73Pj73Pj73PT33Pj73TEz3UFD3UlL4ZGT5gID5i4v5jIz7uLj8v7/8xMT+7u7+8/P///8bRCEdAAAADXRSTlMAHh9ZcnemuePy+Pz+C9A89wAAAI5JREFUKM91ktkWwyAIREeNu3RfUv7/Q/sQUk0a7pPMeBABYMH6mEpJ0VuMmFBJqMF03WUayG7Vp0Yb2iT3dzpRcwBgMv2RDYBABwTASj3n+XnqRrXwcrwyz7fueMRuML8vqxGRRoM/dwkTysZgfi1h0Y1dqscvlfq4Wq76QbUlehPVtuuD0kerL8PR+nwBItQY2sBfPe0AAAAASUVORK5CYII="
              className="van-image__img"
            />
          </div>
          <span className="w-o-h">ดูบอลสด</span>
        </button>
        <div className="matchLive flex" style={{ borderLeft: "none" }}>
          <div className="myIcon van-image van-image--round">
            {/* eslint-disable-next-line */}
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAilBMVEUAAABMkOVKjOZNj+hOj+lPjedOj+hPj+hPj+lPj+hPj+lPj+hPj+hOj+hPj+hRkOhVkuhfmepknOplnepnnupon+tpn+uRufCYvvGfwvKiw/KixPKryfOryvOxzfS+1fa+1va/1vbG2vfI2/fM3vfV5Pnk7fvr8vzz9/31+P32+f34+v7+/v7///+0BOrcAAAADnRSTlMAHh9ZcnemuePy8vj8/vFXrS8AAADNSURBVCjPdVLXAoMgDIwD0aK0dmirdrhabc3//17FvbgXkjs4QgJAC5VQkzGTEhWmUAybd7ANZeS1HZ/A0nped1omy9rV0bv9Hc+rqguc5owy+CAObuIeg68FbtR12luCrQLpYy9BTLw+I0CP72vDF1ijaJTb50TB9PEpkgRT100xEfELfRNYiJFISnQ5d7EUcYQhAxashTsGTFjFS6tYWNHz9768/PG70Lrc/brcQ12u9IHSloBiSZo4tn0QHG0+qDyfD0o+Wvln2Po+f/kWIUfx8SfWAAAAAElFTkSuQmCC"
              className="van-image__img"
            />
          </div>
          <span className="w-o-h">แมตช์ไลฟ์สด</span>
        </div>
      </div>
    );
  }

  // เงื่อนไขที่ 2
  if (status === 88 && status === 99) {
    return (
      <div className="liveBox flex2">
        <button
          className="matchLive flex"
          style={{
            borderTopLeftRadius: "45px",
            borderBottomLeftRadius: "45px",
          }}
        >
          <div className="myIcon van-image van-image--round">
            {/* eslint-disable-next-line */}
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAilBMVEUAAABMkOVKjOZNj+hOj+lPjedOj+hPj+hPj+lPj+hPj+lPj+hPj+hOj+hPj+hRkOhVkuhfmepknOplnepnnupon+tpn+uRufCYvvGfwvKiw/KixPKryfOryvOxzfS+1fa+1va/1vbG2vfI2/fM3vfV5Pnk7fvr8vzz9/31+P32+f34+v7+/v7///+0BOrcAAAADnRSTlMAHh9ZcnemuePy8vj8/vFXrS8AAADNSURBVCjPdVLXAoMgDIwD0aK0dmirdrhabc3//17FvbgXkjs4QgJAC5VQkzGTEhWmUAybd7ANZeS1HZ/A0nped1omy9rV0bv9Hc+rqguc5owy+CAObuIeg68FbtR12luCrQLpYy9BTLw+I0CP72vDF1ijaJTb50TB9PEpkgRT100xEfELfRNYiJFISnQ5d7EUcYQhAxashTsGTFjFS6tYWNHz9768/PG70Lrc/brcQ12u9IHSloBiSZo4tn0QHG0+qDyfD0o+Wvln2Po+f/kWIUfx8SfWAAAAAElFTkSuQmCC"
              className="van-image__img"
            />
          </div>
          <span className="w-o-h">แมตช์ไลฟ์สด</span>
        </button>
      </div>
    );
  }

  // เงื่อนไขที่ 3
  if (
    (status === 1 || status === 2) &&
    match.status_id >= 1 &&
    match.status_id <= 7
  ) {
    return (
      <div className="liveBox flex2">
        <button
          className="liveSream flex"
          style={{
            borderTopRightRadius: "45px",
            borderBottomRightRadius: "45px",
          }}
          onClick={() => onChangeViewMode("liveStream")}
        >
          <div className="myIcon van-image van-image--round">
            {/* eslint-disable-next-line */}
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAUVBMVEUAAAD2Ozv3Ojr2PDz4Pz/2Pj72PT33Pj73Pj74Pj73Pj73Pj73PT33Pj73TEz3UFD3UlL4ZGT5gID5i4v5jIz7uLj8v7/8xMT+7u7+8/P///8bRCEdAAAADXRSTlMAHh9ZcnemuePy+Pz+C9A89wAAAI5JREFUKM91ktkWwyAIREeNu3RfUv7/Q/sQUk0a7pPMeBABYMH6mEpJ0VuMmFBJqMF03WUayG7Vp0Yb2iT3dzpRcwBgMv2RDYBABwTASj3n+XnqRrXwcrwyz7fueMRuML8vqxGRRoM/dwkTysZgfi1h0Y1dqscvlfq4Wq76QbUlehPVtuuD0kerL8PR+nwBItQY2sBfPe0AAAAASUVORK5CYII="
              className="van-image__img"
            />
          </div>
          <span className="w-o-h">ดูบอลสด</span>
        </button>
      </div>
    );
  }

  // เงื่อนไขที่ 4
  if (status === null) {
    return <div className="liveBox flex2"> </div>;
  }
  return null;
};

export default LiveSteamOrMatchLive;
