// Lineups.js
import React from "react";
import "./Lineups.css";
import teamsDefaultImage from "../../../../assets/images/TeamsDefault.webp";
import playersDefaultImage from "../../../../assets/images/playersDefault.webp";

const Lineups = ({ match }) => {
  return (
    <div
      className="event-section event-section--Lineups"
      id="lineups"
      style={{ display: "none" }}
    >
      <div className="event-section__head">
        <div>
          <h3 className="event-section__title">Starting Lineups</h3>
          <div className="event-section__label-confirmed">
            <i className="icon-checked"></i>Confirmed
          </div>
        </div>
      </div>
      <div className="event-section__body">
        <div className="lineup-holder">
          <div className="team-tabs">
            <ul>
              <li>
                <button type="button" className="active">
                  <span className="team-tabs__logo">
                    <img
                      alt={
                        match.Team_Match_home_team_idToTeam.Languages
                          ?.name_th || match.Team_Match_home_team_idToTeam?.name
                      }
                      src={
                        match.Team_Match_home_team_idToTeam.logo_original ||
                        teamsDefaultImage
                      }
                      className="lazyLoad isLoaded"
                    />
                  </span>
                  <span className="team-tabs__text">
                    {match.Team_Match_home_team_idToTeam?.short_name ||
                      match.Team_Match_home_team_idToTeam?.name}
                  </span>
                </button>
              </li>
              <li>
                <button type="button" className="">
                  <span className="team-tabs__logo">
                    <img
                      alt={
                        match.Team_Match_away_team_idToTeam.Languages
                          ?.name_th || match.Team_Match_away_team_idToTeam?.name
                      }
                      src={
                        match.Team_Match_away_team_idToTeam?.logo_original ||
                        teamsDefaultImage
                      }
                      className="lazyLoad isLoaded"
                    />
                  </span>
                  <span className="team-tabs__text">
                    {match.Team_Match_away_team_idToTeam?.short_name ||
                      match.Team_Match_away_team_idToTeam?.name}
                  </span>
                </button>
              </li>
            </ul>
          </div>
          <div className="lineup__outer">
            <div className="lineup lineup--single">
              <div className="lineup__head">
                <div className="lineup__head-inner active">
                  <div style={{ display: "none" }}></div>
                  <span>
                    {match.Team_Match_home_team_idToTeam.Languages?.name_th ||
                      match.Team_Match_home_team_idToTeam?.name}
                  </span>
                  <span>
                    {match.Team_Match_home_team_idToTeam.coach?.name || " "}
                  </span>
                </div>
                <div className="lineup__head-inner">
                  <div style={{ display: "none" }}></div>
                  <span>Luton Town</span>
                  <span>Edwards, Rob</span>
                </div>
              </div>
              <div className="lineup__body">
                <div className="lineup-field lineup-field--single">
                  <div className="lineup-field__area active">
                    <div className="lineup-field__angles">
                      <div className="lineup-field__angle"></div>{" "}
                      <div className="lineup-field__angle"></div>
                    </div>
                    <div className="lineup-field__zebra"></div>
                    <div className="lineup-field__circle"></div>
                    <div className="lineup-field__door">
                      <span></span>
                    </div>
                    <div className="lineup-field__scheme">3-4-2-1</div>
                    <div className="lineup-field__players">
                      <div className="lineup-field__group">
                        <div className="lineup-field__player">
                          <div className="lineup-field__player-head">
                            <div className="lineup-field__player-avatar">
                              <img
                                alt="Sanchez, Robert"
                                width="34"
                                height="34"
                                src={playersDefaultImage}
                                className="lazyLoad isLoaded"
                              />
                            </div>
                            <div className="lineup-field__player-card">
                              <div style={{ display: "none" }}></div>
                              <div style={{ display: "none" }}></div>
                            </div>
                            <div style={{ display: "none" }}></div>
                            <div style={{ display: "none" }}></div>
                            <div style={{ display: "none" }}></div>
                          </div>
                          <span className="lineup-field__player-name">
                            R. Sanchez
                          </span>
                          <span className="lineup-field__player-number">1</span>
                        </div>
                      </div>
                      <div className="lineup-field__group">
                        <div className="lineup-field__player">
                          <div className="lineup-field__player-head">
                            <div className="lineup-field__player-avatar">
                              <img
                                alt="Disasi, Axel"
                                width="34"
                                height="34"
                                src={playersDefaultImage}
                                className="lazyLoad isLoaded"
                              />
                            </div>
                            <div className="lineup-field__player-card">
                              <div style={{ display: "none" }}></div>
                              <div style={{ display: "none" }}></div>
                            </div>
                            <div style={{ display: "none" }}></div>
                            <div style={{ display: "none" }}></div>
                            <div style={{ display: "none" }}></div>
                          </div>
                          <span className="lineup-field__player-name">
                            A. Disasi
                          </span>
                          <span className="lineup-field__player-number">2</span>
                        </div>
                        <div className="lineup-field__player">
                          <div className="lineup-field__player-head">
                            <div className="lineup-field__player-avatar">
                              <img
                                alt="Silva, Thiago"
                                width="34"
                                height="34"
                                src={playersDefaultImage}
                                className="lazyLoad isLoaded"
                              />
                            </div>
                            <div className="lineup-field__player-card">
                              <div style={{ display: "none" }}></div>
                              <div style={{ display: "none" }}></div>
                            </div>
                            <div style={{ display: "none" }}></div>
                            <div style={{ display: "none" }}></div>
                            <div style={{ display: "none" }}></div>
                          </div>
                          <span className="lineup-field__player-name">
                            T. Silva
                          </span>
                          <span className="lineup-field__player-number">6</span>
                        </div>
                        <div className="lineup-field__player">
                          <div className="lineup-field__player-head">
                            <div className="lineup-field__player-avatar">
                              <img
                                alt="Colwill, Levi"
                                width="34"
                                height="34"
                                src={playersDefaultImage}
                                className="lazyLoad isLoaded"
                              />
                            </div>
                            <div className="lineup-field__player-card">
                              <div style={{ display: "none" }}></div>
                              <div style={{ display: "none" }}></div>
                            </div>
                            <div style={{ display: "none" }}></div>
                            <div style={{ display: "none" }}></div>
                            <div style={{ display: "none" }}></div>
                          </div>
                          <span className="lineup-field__player-name">
                            L. Colwill
                          </span>
                          <span className="lineup-field__player-number">
                            26
                          </span>
                        </div>
                      </div>
                      <div className="lineup-field__group">
                        <div className="lineup-field__player">
                          <div className="lineup-field__player-head">
                            <div className="lineup-field__player-avatar">
                              <img
                                alt="Gusto, Malo"
                                width="34"
                                height="34"
                                src={playersDefaultImage}
                                className="lazyLoad isLoaded"
                              />
                            </div>
                            <div className="lineup-field__player-card">
                              <div style={{ display: "none" }}></div>
                              <div style={{ display: "none" }}></div>
                            </div>
                            <div style={{ display: "none" }}></div>
                            <span className="lineup-player-assists">
                              <i className="icon-assist"></i>
                              <span className="lineup-player-stats-counter">
                                {" "}
                                x2{" "}
                              </span>
                            </span>
                            <div style={{ display: "none" }}></div>
                          </div>
                          <span className="lineup-field__player-name">
                            M. Gusto
                          </span>
                          <span className="lineup-field__player-number">
                            27
                          </span>
                        </div>
                        <div className="lineup-field__player">
                          <div className="lineup-field__player-head">
                            <div className="lineup-field__player-avatar">
                              <img
                                alt="Caicedo, Moises"
                                width="34"
                                height="34"
                                src={playersDefaultImage}
                                className="lazyLoad isLoaded"
                              />
                            </div>
                            <div className="lineup-field__player-card">
                              <div style={{ display: "none" }}></div>
                              <div style={{ display: "none" }}></div>
                            </div>
                            <div style={{ display: "none" }}></div>
                            <div style={{ display: "none" }}></div>
                            <div style={{ display: "none" }}></div>
                          </div>
                          <span className="lineup-field__player-name">
                            M. Caicedo
                          </span>
                          <span className="lineup-field__player-number">
                            25
                          </span>
                        </div>
                        <div className="lineup-field__player">
                          <div className="lineup-field__player-head">
                            <div className="lineup-field__player-avatar">
                              <img
                                alt="Fernandez, Enzo"
                                width="34"
                                height="34"
                                src={playersDefaultImage}
                                className="lazyLoad isLoaded"
                              />
                            </div>
                            <div className="lineup-field__player-card">
                              <img
                                alt="Yellow Card"
                                width="12"
                                height="14"
                                src=""
                                className="lazyLoad isLoaded"
                              />
                              <div style={{ display: "none" }}></div>
                            </div>
                            <div style={{ display: "none" }}></div>
                            <div style={{ display: "none" }}></div>
                            <div style={{ display: "none" }}></div>
                          </div>
                          <span className="lineup-field__player-name">
                            E. Fernandez
                          </span>
                          <span className="lineup-field__player-number">8</span>
                        </div>
                        <div className="lineup-field__player">
                          <div className="lineup-field__player-head">
                            <div className="lineup-field__player-avatar">
                              <img
                                alt="Chilwell, Benjamin"
                                width="34"
                                height="34"
                                src={playersDefaultImage}
                                className="lazyLoad isLoaded"
                              />
                            </div>
                            <div className="lineup-field__player-card">
                              <div style={{ display: "none" }}></div>
                              <div style={{ display: "none" }}></div>
                            </div>
                            <div style={{ display: "none" }}></div>
                            <div style={{ display: "none" }}></div>
                            <div className="lineup-field__player-status">
                              <span className="lineup-field__player-status__time">
                                86'
                              </span>
                              <img
                                alt="Substitute Out"
                                width="16"
                                height="16"
                                src=""
                                className="lazyLoad isLoaded"
                              />
                            </div>
                          </div>
                          <span className="lineup-field__player-name">
                            B. Chilwell
                          </span>
                          <span className="lineup-field__player-number">
                            21
                          </span>
                        </div>
                      </div>
                      <div className="lineup-field__group">
                        <div className="lineup-field__player">
                          <div className="lineup-field__player-head">
                            <div className="lineup-field__player-avatar">
                              <img
                                alt="Sterling, Raheem"
                                width="34"
                                height="34"
                                src={playersDefaultImage}
                                className="lazyLoad isLoaded"
                              />
                            </div>
                            <div className="lineup-field__player-card">
                              <div style={{ display: "none" }}></div>
                              <div style={{ display: "none" }}></div>
                            </div>
                            <span className="lineup-player-goals">
                              <img
                                alt="Goal icon"
                                width="16"
                                height="16"
                                src=""
                                className="lazyLoad isLoaded"
                              />
                              <span className="lineup-player-stats-counter">
                                {" "}
                                x2{" "}
                              </span>
                            </span>
                            <div style={{ display: "none" }}></div>
                            <div className="lineup-field__player-status">
                              <span className="lineup-field__player-status__time">
                                90'
                              </span>
                              <img
                                alt="Substitute Out"
                                width="16"
                                height="16"
                                src=""
                                className="lazyLoad isLoaded"
                              />
                            </div>
                          </div>
                          <span className="lineup-field__player-name">
                            R. Sterling
                          </span>
                          <span className="lineup-field__player-number">7</span>
                        </div>
                        <div className="lineup-field__player">
                          <div className="lineup-field__player-head">
                            <div className="lineup-field__player-avatar">
                              <img
                                alt="Gallagher, Conor"
                                width="34"
                                height="34"
                                src={playersDefaultImage}
                                className="lazyLoad isLoaded"
                              />
                            </div>
                            <div className="lineup-field__player-card">
                              <div style={{ display: "none" }}></div>
                              <div style={{ display: "none" }}></div>
                            </div>
                            <div style={{ display: "none" }}></div>
                            <div style={{ display: "none" }}></div>
                            <div style={{ display: "none" }}></div>
                          </div>
                          <span className="lineup-field__player-name">
                            C. Gallagher
                          </span>
                          <span className="lineup-field__player-number">
                            23
                          </span>
                        </div>
                      </div>
                      <div className="lineup-field__group">
                        <div className="lineup-field__player">
                          <div className="lineup-field__player-head">
                            <div className="lineup-field__player-avatar">
                              <img
                                alt="Jackson, Nicolas"
                                width="34"
                                height="34"
                                src={playersDefaultImage}
                                className="lazyLoad isLoaded"
                              />
                            </div>
                            <div className="lineup-field__player-card">
                              <div style={{ display: "none" }}></div>
                              <div style={{ display: "none" }}></div>
                            </div>
                            <span className="lineup-player-goals">
                              <img
                                alt="Goal icon"
                                width="16"
                                height="16"
                                src=""
                                className="lazyLoad isLoaded"
                              />
                              <div style={{ display: "none" }}></div>
                            </span>
                            <div style={{ display: "none" }}></div>
                            <div className="lineup-field__player-status">
                              <span className="lineup-field__player-status__time">
                                83'
                              </span>
                              <img
                                alt="Substitute Out"
                                width="16"
                                height="16"
                                src=""
                                className="lazyLoad isLoaded"
                              />
                            </div>
                          </div>
                          <span className="lineup-field__player-name">
                            N. Jackson
                          </span>
                          <span className="lineup-field__player-number">
                            15
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lineup-field__area lineup-field__area--reverse">
                    <div className="lineup-field__angles">
                      <div className="lineup-field__angle"></div>
                      <div className="lineup-field__angle"></div>
                    </div>
                    <div className="lineup-field__zebra"></div>
                    <div className="lineup-field__circle"></div>
                    <div className="lineup-field__door">
                      <span></span>
                    </div>
                    <div className="lineup-field__scheme">3-5-2</div>
                    <div className="lineup-field__players">
                      <div className="lineup-field__group">
                        <div className="lineup-field__player">
                          <div className="lineup-field__player-head">
                            <div className="lineup-field__player-avatar">
                              <img
                                alt="Kaminski, Thomas"
                                width="34"
                                height="34"
                                src={playersDefaultImage}
                                className="lazyLoad isLoaded"
                              />
                            </div>
                            <div className="lineup-field__player-card">
                              <div style={{ display: "none" }}></div>
                              <div style={{ display: "none" }}></div>
                            </div>
                            <div style={{ display: "none" }}></div>
                            <div style={{ display: "none" }}></div>
                            <div style={{ display: "none" }}></div>
                          </div>
                          <span className="lineup-field__player-name">
                            T. Kaminski
                          </span>
                          <span className="lineup-field__player-number">
                            24
                          </span>
                        </div>
                      </div>
                      <div className="lineup-field__group">
                        <div className="lineup-field__player">
                          <div className="lineup-field__player-head">
                            <div className="lineup-field__player-avatar">
                              <img
                                alt="Kaminski, Thomas"
                                width="34"
                                height="34"
                                src={playersDefaultImage}
                                className="lazyLoad isLoaded"
                              />
                            </div>
                            <div className="lineup-field__player-card">
                              <div style={{ display: "none" }}></div>
                              <div style={{ display: "none" }}></div>
                            </div>
                            <div style={{ display: "none" }}></div>
                            <div style={{ display: "none" }}></div>
                            <div style={{ display: "none" }}></div>
                          </div>
                          <span className="lineup-field__player-name">
                            T. Kaminski
                          </span>
                          <span className="lineup-field__player-number">
                            24
                          </span>
                        </div>
                      </div>
                      <div className="lineup-field__group">
                        <div className="lineup-field__player">
                          <div className="lineup-field__player-head">
                            <div className="lineup-field__player-avatar">
                              <img
                                alt="Kaminski, Thomas"
                                width="34"
                                height="34"
                                src={playersDefaultImage}
                                className="lazyLoad isLoaded"
                              />
                            </div>
                            <div className="lineup-field__player-card">
                              <div style={{ display: "none" }}></div>
                              <div style={{ display: "none" }}></div>
                            </div>
                            <div style={{ display: "none" }}></div>
                            <div style={{ display: "none" }}></div>
                            <div style={{ display: "none" }}></div>
                          </div>
                          <span className="lineup-field__player-name">
                            T. Kaminski
                          </span>
                          <span className="lineup-field__player-number">
                            24
                          </span>
                        </div>
                      </div>
                      <div className="lineup-field__group">
                        <div className="lineup-field__player">
                          <div className="lineup-field__player-head">
                            <div className="lineup-field__player-avatar">
                              <img
                                alt="Kaminski, Thomas"
                                width="34"
                                height="34"
                                src={playersDefaultImage}
                                className="lazyLoad isLoaded"
                              />
                            </div>
                            <div className="lineup-field__player-card">
                              <div style={{ display: "none" }}></div>
                              <div style={{ display: "none" }}></div>
                            </div>
                            <div style={{ display: "none" }}></div>
                            <div style={{ display: "none" }}></div>
                            <div style={{ display: "none" }}></div>
                          </div>
                          <span className="lineup-field__player-name">
                            T. Kaminski
                          </span>
                          <span className="lineup-field__player-number">
                            24
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lineup__foot">
                <div className="lineup__foot-inner">
                  <i className="icon-stadium"></i>
                  {match.venue?.name || " "}
                </div>{" "}
                <div className="lineup__foot-inner">
                  <i className="icon-whistle"></i>
                  {match.referee?.name || " "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lineups;
