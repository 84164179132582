import React from "react";
import "./MatchStats.css";
import teamsDefaultImage from "../../../../assets/images/TeamsDefault.webp";

function MatchStats({
  homeTeamLogo,
  homeTeamName,
  awayTeamLogo,
  awayTeamName,
  incidents,
  stats,
}) {
  const possessionStat = stats && stats.find((stat) => stat.type === 25);
  let homePossession = possessionStat && possessionStat.home;
  let awayPossession = possessionStat && possessionStat.away;

  if (homePossession === 0 && awayPossession === 0) {
    homePossession = 50;
    awayPossession = 50;
  }

  const getStatValue = (type, team = "home") => {
    const statItem = stats && stats.find((stat) => stat.type === type);
    return statItem ? statItem[team] : 0;
  };

  const shotsOnTargetHome = getStatValue(21, "home");
  const shotsOnTargetAway = getStatValue(21, "away");

  const type22Home = getStatValue(22, "home");
  const type22Away = getStatValue(22, "away");

  const shotsHome = type22Home + shotsOnTargetHome;
  const shotsAway = type22Away + shotsOnTargetAway;

  const yellowCardHome = getStatValue(3, "home");
  const yellowCardAway = getStatValue(3, "away");
  const redCardHome = getStatValue(4, "home");
  const redCardAway = getStatValue(4, "away");

  return (incidents && incidents.length > 0) || possessionStat ? (
    <div className="pop-box">
      <span className="name-box">
        <span className="text home">
          <span className="teamLogoBox teamLogoHomeBox">
            <img src={homeTeamLogo || teamsDefaultImage} alt={homeTeamName} />
          </span>
          <span className="hintBoxName hintBoxNameHome bh">{homeTeamName}</span>
        </span>
        <span className="text away">
          <span className="hintBoxName hintBoxNameAway bh">{awayTeamName}</span>
          <span className="teamLogoBox teamLogoAwayBox">
            <img src={awayTeamLogo || teamsDefaultImage} alt={awayTeamName} />
          </span>
        </span>
      </span>
      {incidents && incidents.length > 0 && (
        <span className="incidents-box">
          {incidents.map(
            (incident, index) =>
              (incident.type === 1 || incident.type === 8) && ( // เพิ่มเงื่อนไขนี้
                <span key={index} className="item">
                  {incident.position === 1 && (
                    <>
                      <span className="home">
                        {incident.player_name && (
                          <span className="playerName homePlayerName">
                            {incident.player_name}
                          </span>
                        )}
                        {incident.type === 1 && <i className="futbol icon"></i>}
                        {incident.type === 8 && (
                          <i className="futbol icon red"></i>
                        )}
                      </span>
                      <span className="text">{incident.time}'</span>
                      <span className="away"></span>
                    </>
                  )}

                  {incident.position === 2 && (
                    <>
                      <span className="home"></span>
                      <span className="text">{incident.time}'</span>
                      <span className="away">
                        {incident.type === 1 && <i className="futbol icon"></i>}
                        {incident.type === 8 && (
                          <i className="futbol icon red"></i>
                        )}
                        {incident.player_name && (
                          <span className="playerName awayPlayerName">
                            {incident.player_name}
                          </span>
                        )}
                      </span>
                    </>
                  )}
                </span>
              )
          )}
        </span>
      )}

      {possessionStat && (
        <span className="stats-box">
          <span className="possessionText">การครองบอล</span>
          <span className="possession">
            <span
              className="possessionHome"
              style={{ width: `${homePossession}%` }}
            >
              {homePossession}%
            </span>
            <span
              className="possessionAway"
              style={{ width: `${awayPossession}%` }}
            >
              {awayPossession}%
            </span>
          </span>
          <span className="stats-item">
            <span className="home">
              <span className="number numberHomeColor">{shotsHome}</span>
            </span>
            <span className="text">การยิง</span>
            <span className="away">
              <span className="number">{shotsAway}</span>
            </span>
          </span>
          <span className="stats-item">
            <span className="home">
              <span className="number numberHomeColor">
                {shotsOnTargetHome}
              </span>
            </span>
            <span className="text">ยิงเข้ากรอบ</span>
            <span className="away">
              <span className="number">{shotsOnTargetAway}</span>
            </span>
          </span>
          <span className="stats-item">
            <span className="home">
              <span className="number numberHomeColor">{yellowCardHome}</span>
            </span>
            <span className="text">ใบเหลือง</span>
            <span className="away">
              <span className="number">{yellowCardAway}</span>
            </span>
          </span>
          <span className="stats-item">
            <span className="home">
              <span className="number numberHomeColor">{redCardHome}</span>
            </span>
            <span className="text">ใบแดง</span>
            <span className="away">
              <span className="number">{redCardAway}</span>
            </span>
          </span>
        </span>
      )}
    </div>
  ) : null;
}

export default MatchStats;
