import React, { useState, useEffect } from "react";
import teamsDefaultImage from "../../../../../assets/images/TeamsDefault.webp";
import { Goal } from "../../../../../assets/images/icons";
import "./EventHeaderGoalScorers.css";

const EventHeaderGoalScorers = ({ match }) => {
  const incidents = match?.incidents || [];

  if (Object.keys(incidents).length === 0) {
    return null; // ถ้าไม่มีข้อมูล ไม่แสดงส่วนนี้
  }

  const homeScorers = incidents
    .filter(
      (incident) =>
        incident?.position === 1 &&
        (incident?.type === 1 || incident?.type === 8)
    )
    .map(
      (incident) =>
        `${incident?.player_name ? incident?.player_name : ""} (${
          incident?.time
        }')`
    );

  const awayScorers = incidents
    .filter(
      (incident) =>
        incident?.position === 2 &&
        (incident?.type === 1 || incident?.type === 8)
    )
    .map(
      (incident) =>
        `${incident?.player_name ? incident?.player_name : ""} (${
          incident?.time
        }')`
    );

  return incidents.length > 0 ? (
    <div className="event-header-goal-scorers">
      <div className="event-header-goal-scorers__items">
        {homeScorers.map((scorer, index) => (
          <div key={index} className="event-header-goal-scorers__item">
            {scorer}
          </div>
        ))}
      </div>
      <div className="event-header-goal-scorers__icon">
        <i className="futbol icon"></i>
      </div>
      <div className="event-header-goal-scorers__items">
        {awayScorers.map((scorer, index) => (
          <div key={index} className="event-header-goal-scorers__item">
            <div className="event-header-goal-scorers__item">{scorer}</div>
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export default EventHeaderGoalScorers;
