import { SET_LIVE_COUNT } from "../actions";

const initialState = {
  liveCount: 0,
};

const liveCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LIVE_COUNT:
      return {
        ...state,
        liveCount: action.payload,
      };
    default:
      return state;
  }
};

export default liveCountReducer;
