import React, { useEffect } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-flvjs";

function VideoPlayerFlv({ src }) {
  const videoRef = React.useRef(null);

  useEffect(() => {
    const player = videojs(videoRef.current, {
      controls: true,
      isLive: true,
      autoplay: true,
      preload: "auto",
      playbackRates: [0.5, 1, 1.5, 2],
      sources: [
        {
          src,
          type: "flv",
        },
      ],
    });

    return () => {
      if (player) {
        player.dispose();
      }
    };
  }, [src]);

  return (
    <div data-vjs-player>
      <video
        ref={videoRef}
        className="video-js vjs-default-skin"
        playsInline
      ></video>
    </div>
  );
}

export default VideoPlayerFlv;
