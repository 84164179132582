import React from "react";
import "./EventHeaderInPlayWidgetsGridMomentum.css";

const EventHeaderInPlayWidgetsGridMomentum = ({ match }) => {
  // ตรวจสอบว่า match.trend_first_half และ match.trend_second_half มี properties ภายในหรือไม่
  if (
    Object.keys(match.trend_first_half).length === 0 &&
    Object.keys(match.trend_second_half).length === 0
  ) {
    return null; // ถ้าไม่มีข้อมูล ไม่แสดงส่วนนี้
  }
  const combinedTrend = [
    ...match?.trend_first_half,
    ...match?.trend_second_half,
  ];
  const lengthToUse = combinedTrend.length > 93 ? combinedTrend.length : 93;
  return (
    <div className="event-header-in-play-widgets-grid__momentum">
      <div class="dangerTitle flex justify-between">
        <div className="w100 dangerTitleInner">
          <span className="w-o-h">
            {match?.Team_Match_home_team_idToTeam?.name
              .replace(/\s+/g, "")
              .slice(0, 3)
              .toUpperCase()}
          </span>
        </div>
        <div
          class="w100 dangerTitleInner"
          style={{ justifyContent: "flex-end" }}
        >
          <span className="w-o-h">
            {match?.Team_Match_away_team_idToTeam?.name
              .replace(/\s+/g, "")
              .slice(0, 3)
              .toUpperCase()}
          </span>
        </div>
      </div>
      <div className="dangerBox">
        <div className="contentBox">
          <div className="w100" style={{ height: "50%", position: "relative" }}>
            <div
              className="w100 h100 flex"
              style={{ position: "absolute", flexDirection: "column" }}
            >
              <div className="flex-1"></div>
              <div
                className="flex-1 w100"
                style={{ background: "#edf7fdcc" }}
              ></div>
              <div
                className="flex-1 w100"
                style={{ background: "#d2e9fecc" }}
              ></div>
            </div>
            <div className="w100 h100 flex" style={{ position: "absolute" }}>
              <div
                className="w100 flex h100"
                style={{ transform: "rotateX(180deg)" }}
              >
                {Array.from({ length: lengthToUse }).map((_, index) => {
                  const heightValue = combinedTrend[index];
                  const finalHeight =
                    heightValue && heightValue > 0 ? heightValue : 0;

                  return (
                    <div
                      key={index}
                      id={`home_${index}`}
                      className="diyDiv"
                      style={{
                        marginLeft: "1px",
                        width: "calc(100% / 100)",
                        height: `${finalHeight / 1.5}%`,
                        background: "#4575d6", //สีแท่งกราฟฝั่งทีมเจ้าบ้าน
                      }}
                    ></div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="w100" style={{ height: "50%", position: "relative" }}>
            <div
              className="w100 h100 flex"
              style={{ position: "absolute", flexDirection: "column" }}
            >
              <div class="flex-1" style={{ background: "#fff3e0cc" }}></div>
              <div
                class="flex-1 w100"
                style={{ background: "#fffbf5cc" }}
              ></div>
              <div className="flex-1 w100"></div>
            </div>
            <div className="w100 h100 flex" style={{ position: "absolute" }}>
              <div className="w100 flex h100">
                {Array.from({ length: lengthToUse }).map((_, index) => {
                  const heightValue = combinedTrend[index]
                    ? -combinedTrend[index]
                    : undefined;
                  const finalHeight =
                    heightValue && heightValue > 0 ? heightValue : 0;

                  return (
                    <div
                      key={index}
                      id={`away_${index}`}
                      className="diyDiv"
                      style={{
                        marginLeft: "1px",
                        width: "calc(100% / 100)",
                        height: `${finalHeight / 1.5}%`,
                        background: "#de4e52", //สีแท่งกราฟฝั่งทีมเยือน
                      }}
                    ></div>
                  );
                })}
              </div>
            </div>
          </div>
          <div
            className="w100 h100 columnLineBox"
            style={{ position: "absolute" }}
          >
            {[0, 15, 30, 45, 60, 75, 90].map((value, index) => (
              <div
                key={value}
                className="yValue"
                style={{
                  left: `calc(${value} *  calc(100% - 7px) / 100)`,
                  marginLeft: `${index}px`,
                }}
              ></div>
            ))}

            {[0, 15, 30, 45, 60, 75, 90].map((value, index) => (
              <span
                key={value}
                className="yText"
                style={{
                  left: `calc(${value} *  calc(100% - 7px) / 100)`,
                  marginLeft: `0px`,
                  // marginLeft: `${-index}px`,
                }}
              >
                {value + "'"}
              </span>
            ))}
          </div>
          <div
            className="w100 h100 eventBox flex"
            style={{ position: "absolute", flexDirection: "column" }}
          >
            <div
              className="w100"
              style={{ height: "50%", position: "relative" }}
            ></div>
            <div
              className="w100"
              style={{ height: "50%", position: "relative" }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TeamIcons = ({ match, position }) => {
  const incidents = match?.incidents.filter(
    (incident) => incident.position === position
  );
  return (
    <div className="team-icons">
      {incidents.map((incident) => (
        <Icon key={incident.time} type={incident.type} />
      ))}
    </div>
  );
};

const Icon = ({ type }) => {
  switch (type) {
    case 1:
      return <span className="goal-icon">⚽</span>;
    case 2:
      return <span className="corner-icon">🚩</span>;
    case 3:
      return <span className="yellow-card-icon">🟨</span>;
    case 4:
      return <span className="red-card-icon">🟥</span>;
    case 9:
      return <span className="substitution-icon">🔄</span>;
    default:
      return null;
  }
};

const BarGraph = ({ data }) => {
  return (
    <div className="bar-graph">
      {data.map((value, index) => (
        <Bar key={index} value={value} />
      ))}
    </div>
  );
};

const Bar = ({ key, value }) => {
  const isHome = value > 0;
  const style = {
    height: `${Math.abs(value)}%`,
    backgroundColor: isHome ? "blue" : "red",
  };
  return <div id={key} className="bar" style={style} />;
};

export default EventHeaderInPlayWidgetsGridMomentum;
