import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSingleMatch,
  fetchMatchLiveStream,
  fetchSingleMatchLive,
} from "../../redux/actions";
import "./Match.css";
import EventHeaderWrapper from "./EventHeaderWrapper/EventHeaderWrapper";
import ContentWrapper from "./ContentWrapper/ContentWrapper";
import LoadingPage from "../LoadingPage/LoadingPage";

const Match = () => {
  const { matchId } = useParams();
  const dispatch = useDispatch();
  const match = useSelector((state) => state.matches.singleMatch);
  const playUrl = useSelector((state) => state.liveStream.matchLiveStream);

  const liveStatusIds = [2, 3, 4, 5, 6, 7];
  const intervalRef = useRef(null);
  const [hasFetchedLiveStream, setHasFetchedLiveStream] = useState(false);

  useEffect(() => {
    fetchTrendData();
    dispatch(fetchSingleMatch(matchId));
  }, [matchId, dispatch]);

  useEffect(() => {
    if (
      match &&
      liveStatusIds.includes(match.status_id) &&
      !hasFetchedLiveStream
    ) {
      dispatch(fetchMatchLiveStream(matchId));
      setHasFetchedLiveStream(true);
    }
  }, [match, dispatch, matchId, hasFetchedLiveStream]);

  useEffect(() => {
    if (match && liveStatusIds.includes(match.status_id)) {
      intervalRef.current = setInterval(() => {
        dispatch(fetchSingleMatchLive(matchId));
      }, 10000);

      return () => {
        clearInterval(intervalRef.current);
      };
    }
  }, [match, dispatch, matchId]);

  const [timeToMatchStart, setTimeToMatchStart] = useState(null);

  useEffect(() => {
    if (match && match.status_id === 1) {
      const currentTime = new Date().getTime();
      const matchStartTime = new Date(match.match_time).getTime();
      const timeDifference = matchStartTime - currentTime;

      if (timeDifference <= 0) {
        dispatch(fetchMatchLiveStream(matchId));
        intervalRef.current = setInterval(() => {
          dispatch(fetchSingleMatchLive(matchId));
        }, 10000);
      } else {
        setTimeToMatchStart(timeDifference);
      }
    }
  }, [match, dispatch, matchId]);

  useEffect(() => {
    if (timeToMatchStart && timeToMatchStart > 0) {
      const timeout = setTimeout(() => {
        dispatch(fetchMatchLiveStream(matchId));
        intervalRef.current = setInterval(() => {
          dispatch(fetchSingleMatchLive(matchId));
        }, 10000);
      }, timeToMatchStart);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [timeToMatchStart, dispatch, matchId]);

  const trendIntervalRef = useRef(null);

  useEffect(() => {
    if (match && liveStatusIds.includes(match.status_id)) {
      // Fetch trend data immediately
      fetchTrendData();

      // Then fetch every 1 minute
      trendIntervalRef.current = setInterval(() => {
        fetchTrendData();
      }, 60000); // 60000 milliseconds = 1 minute

      return () => {
        clearInterval(trendIntervalRef.current);
      };
    }
  }, [match]);

  const fetchTrendData = async () => {
    if (match && liveStatusIds.includes(match.status_id)) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/match/trend?matchId=${matchId}`
        );
        const data = await response.json();
        // Handle the data as needed, for example, dispatch it to the Redux store
        // dispatch(yourActionToStoreData(data));
      } catch (error) {
        //console.error("Error fetching trend data:");
      }
    }
  };

  if (!match) {
    return <LoadingPage />;
  }

  return (
    <main className="content content--event">
      <div className="event-page-wrapper">
        <div className="event-wrapper event-wrapper--main">
          <EventHeaderWrapper match={match} playUrl={playUrl} />
          <ContentWrapper match={match} />
        </div>
      </div>
    </main>
  );
};

export default Match;
