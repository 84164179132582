import React, { useState } from "react";
import "./Header.css";
import FlagTH from "../../assets/images/FlagTH.svg";
import { LogoKub } from "../../assets/images/icons";

function HeaderMobile() {
  const [isMenuActive, setMenuActive] = useState(false); // Add this line
  return (
    <div className="wrapper is-mobile-or-tablet has-bottom-bar is-mobile page-home page-index___en">
      <header className="header-mobile">
        <div className="container">
          <div className="header__wrap">
            <div className="header__inner">
              <div className="nav-mobile__wrap">
                <button
                  className={`nav-mobile__toggle ${
                    isMenuActive ? "active" : ""
                  }`}
                  onClick={() => setMenuActive(!isMenuActive)}
                >
                  <span></span>
                </button>
                <nav className={`nav-mobile ${isMenuActive ? "active" : ""}`}>
                  <div className="nav-mobile__inner">
                    <div className="nav-mobile__main">
                      <div className="nav-mobile__section">
                        <ul className="navigation-list-links">
                          <li className="navigation-list-links-item">
                            <a
                              href="/"
                              className="navigation-link navigation-link--primary"
                            >
                              <div className="navigation-link__image">
                                <span>
                                  <i className="home icon mobile__icon"></i>
                                </span>
                              </div>
                              <div className="navigation-link__text_menu">
                                Home
                              </div>
                            </a>
                          </li>
                          <li className="navigation-list-links-item">
                            <a
                              href="/"
                              className="navigation-link navigation-link--primary"
                            >
                              <div className="navigation-link__image">
                                <span>
                                  <i className="podcast icon mobile__icon"></i>
                                </span>
                              </div>
                              <div className="navigation-link__text_menu">
                                Live
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="nav-mobile__dropdowns"></div>
                  </div>
                </nav>
              </div>
              <a href="/" className="logo">
                <LogoKub />
              </a>
              <div className="header-utility-nav header-utility-nav--mobile">
                <div className="header-utility-nav__inner">
                  <div className="search header-utility-nav__action">
                    <button className="search__toggle">
                      <i className="search icon search__icon"></i>
                    </button>
                  </div>
                  <div
                    className="header-utility-nav__action"
                    style={{ display: "none" }}
                  >
                    <a
                      href="/favourites"
                      title="Favourites"
                      className="header-utility-nav__btn header-utility-nav__btn--favorites"
                    >
                      <i className="star outline icon mobile__icon"></i>
                    </a>
                  </div>
                  <div
                    className="user-dropdown header-utility-nav__action"
                    style={{ display: "none" }}
                  >
                    <button
                      data-e2e="header-profile-btn"
                      className="user-dropdown__btn-icon"
                    >
                      <i className="user icon mobile__icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default HeaderMobile;
