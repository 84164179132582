// MatchListSubNav.js
import React, { useState, useEffect, useRef } from "react";
import "./MatchListSubNav.css";
import { Calendar, Card } from "react-rainbow-components";
import {
  selectDate,
  fetchMatches,
  fetchMatchLive,
} from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";

const MatchListSubNav = ({
  liveCount,
  toggleLive,
  isToggledByTime, // ใช้เป็น boolean
  onToggleByTime, // ใช้เป็น callback function
  updateSelectedDate,
  selectedDateMatchList,
}) => {
  const dispatch = useDispatch();
  const calendarContainerStyles = {
    width: "28rem",
    height: "27rem",
    position: "absolute",
    top: "40px",
    "border-radius": "19px",
    backgroundColor: "#f5f7f9",
    "z-index": "9999",
  };

  const dateFromLive = selectedDateMatchList;
  const [selectedButton, setSelectedButton] = useState("live");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const calendarRef = useRef();

  const handleLiveClick = () => {
    setSelectedButton("live");
    setSelectedDate(new Date());
    updateSelectedDate(new Date());
    toggleLive(true);
  };

  const handleBytime = () => {
    onToggleByTime(!isToggledByTime); // สลับค่า isToggledByTime
  };

  const handleAllClick = () => {
    //console.log(dateFromLive);
    //setSelectedDate(updateSelectedDate);
    setSelectedButton("all");
    toggleLive(false);
  };

  const handleDateChange = (date) => {
    updateSelectedDate(date);
    setSelectedDate(date);
    setSelectedButton("all");
    toggleLive(false);
  };

  const changeSelectedDate = (date) => {
    updateSelectedDate(date);
    setSelectedDate(date);
    setSelectedButton("all");
    toggleLive(false);
  };

  const handleClickOutside = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setIsCalendarVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const currentDate = new Date();

  const dates = Array.from(
    { length: 5 },
    (_, i) =>
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - 2 + i
      )
  );

  return (
    <div className="match-list-sub-nav">
      <button
        type="button"
        className={`btn-filter mobile ${isToggledByTime ? "active" : ""}`}
        onClick={handleBytime}
      >
        <span className="sort_by_time">🕗</span>
      </button>
      <div className="sortByBox" onClick={handleBytime}>
        <label className="el-checkbox">
          <input
            type="checkbox"
            className="el-checkbox__input"
            checked={isToggledByTime}
            onChange={(e) => {
              e.stopPropagation();
              handleBytime();
            }}
          />
          <span className="el-checkbox__inner"></span>
        </label>
        <span className="sortByText bh">เรียงตามเวลา</span>
      </div>
      <div className="match-list-filter-buttons">
        <button
          type="button"
          className={`btn-filter ${selectedButton === "live" ? "active" : ""}`}
          onClick={handleLiveClick}
        >
          <span className="live_text">ถ่ายทอดสด</span>
          <span className="btn__label bg-danger">{liveCount}</span>
        </button>
        <button
          className={`btn-filter ${selectedButton === "all" ? "active" : ""}`}
          onClick={handleAllClick}
        >
          <span className="live_text">ทั้งหมด</span>
        </button>
      </div>
      <div className="match-list-date-picker">
        <ul className="nav-calendar-desktop m-0">
          {dates.map((date, index) => (
            <li
              key={index}
              className={`
    nav-calendar-desktop__item 
    ${date.getDate() === selectedDate.getDate() ? "active" : ""}
    ${index === 0 || index === 4 ? "hide-item" : ""}
    ${date.getDate() === selectedDate.getDate() ? "disabled" : ""}
  `}
              onClick={() => {
                if (date.getDate() !== selectedDate.getDate()) {
                  changeSelectedDate(date);
                }
              }}
            >
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className="">
                {date.getDate()}{" "}
                <span>
                  {date.toLocaleDateString("default", { weekday: "short" })}
                </span>
              </a>
            </li>
          ))}
          <li ref={calendarRef} className="nav-calendar-desktop__item">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a onClick={() => setIsCalendarVisible(!isCalendarVisible)}>
              <i className="calendar outline icon"></i>
            </a>
            {isCalendarVisible && (
              <Card
                style={calendarContainerStyles}
                className="rainbow-p-around_large"
              >
                <Calendar
                  className="calendar_select"
                  value={selectedDate}
                  onChange={handleDateChange}
                />
              </Card>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MatchListSubNav;
