// EventLivestream.js
import React, { useState, useEffect, useRef } from "react";
import "./EventLivestream.css";
import footballCover from "../../../../../../assets/images/football-cover.webp";
import { LogoKub } from "../../../../../../assets/images/icons";
import JessibucaPlayer from "react-jessibuca";
import VideoPlayer from "./VideoPlayer";
import VideoPlayerFlv from "./VideoPlayerFlv";
import FlvPlayer from "./FlvPlayer";
//const STREAM_MODE = process.env.REACT_APP_STREAM_MODE;

const EventLivestream = ({ match, player1, player2, player3 }) => {
  const nowPlay = player1;
  const isComming = `${window.location.origin}/comming.flv`;
  const [isPlaying, setIsPlaying] = useState(true);
  const playerRef = React.useRef(null);
  //const liveMode = localStorage.getItem("liveMode");
  let player;
  let wakeLock = null;

  // ฟังก์ชันนี้จะขอ Wake Lock
  const requestWakeLock = async () => {
    try {
      wakeLock = await navigator.wakeLock.request("screen");
      wakeLock.addEventListener("release", () => {
        console.log("Wake Lock was released");
      });
      console.log("Wake Lock is active");
    } catch (err) {
      console.error(`Error with Wake Lock: ${err.name}, ${err.message}`);
    }
  };

  // ฟังก์ชันนี้จะปล่อย Wake Lock
  const releaseWakeLock = () => {
    if (wakeLock !== null) {
      wakeLock.release();
      wakeLock = null;
    }
  };

  const handlePlayer1Click = () => {
    if (player) {
      player.src({
        url: `${process.env.REACT_APP_STREAM_URL}/api/match/liveStreamV2?l=${player1}`,
        type: "flv",
      });
    }
  };

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.setVolume(1);
      //requestWakeLock();
      // หรือ
      // playerRef.current.play();
    }
  }, []);

  const playVideo = () => {
    setIsPlaying(true);
  };

  return (
    <div className="event-livestream-inplay">
      <div className="event-livestream-inplay__body">
        <div className="event-livestream-inplay__aside">
          <span className="go-link cursor-pointer livestream-cover animate">
            {!isPlaying && (
              <div className="livestream-cover__image livestream-cover__image--football"></div>
            )}
            {!isPlaying && (
              <div className="livestream-cover__button-simple"></div>
            )}
            {!isPlaying && (
              <div className="livestream-cover__button" onClick={playVideo}>
                <span className="livestream-cover__button__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.834"
                    height="10.818"
                    viewBox="0 0 7.834 10.818"
                  >
                    <path
                      d="M7.6 4.956L.885.107a.56.56 0 00-.887.454v9.7a.56.56 0 00.887.454L7.6 5.866a.56.56 0 000-.907z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </span>
                <span className="livestream-cover__button__text">Watch</span>
              </div>
            )}
            {isPlaying && (
              <div className="video-container">
                {localStorage.getItem("liveMode") === "iframe" ? (
                  <iframe
                    className="player iframe"
                    scrolling="no"
                    src={`https://stream.raysports.live/th/football?token=vzrxlhjezh6uyrd&uuid=${match.match_id}&v=1.0.2`}
                    allowFullScreen={true}
                    webkitallowfullscreen={true}
                    mozallowfullscreen={true}
                  ></iframe>
                ) : localStorage.getItem("liveMode") === "iOS_MODE" ? (
                  // <JessibucaPlayer
                  //   ref={playerRef}
                  //   src={player1 || isComming}
                  //   mute={false}
                  //   decoder="/decoder.js"
                  //   loadingText="กำลังโหลดข้อมูล..."
                  //   controls={{
                  //     fullscreen: true,
                  //     audio: true,
                  //     play: false,
                  //     screenshot: false,
                  //     record: false,
                  //   }}
                  //   volume={1}
                  // />
                  <VideoPlayer src={player1} poster={footballCover} />
                ) : localStorage.getItem("liveMode") === "WINDOWS_MODE" ||
                  "ANDROID_MODE" ? (
                  // <Artplayer
                  //   option={{
                  //     container: ".artplayer-app",
                  //     url: player1 || isComming,
                  //     type: "flv",
                  //     autoplay: true,
                  //   }}
                  //   style={{
                  //     width: "600px",
                  //     height: "400px",
                  //   }}
                  //   // getInstance={(art) => console.info(art)}
                  // />
                  //<VideoPlayerFlv src={player1 || isComming} />
                  <FlvPlayer
                    src={player1 || isComming}
                    poster={footballCover}
                  />
                ) : (
                  <div className="container-shell"></div>
                )}
              </div>
            )}
            {!isPlaying && (
              <div className="livestream-cover__caption">
                <div className="livestream-cover__progress">
                  <span className="livestream-cover__progress__inner"></span>
                </div>
                <div className="livestream-cover__caption__content">
                  {match.Team_Match_home_team_idToTeam?.Language?.[0]
                    ?.name_th || match.Team_Match_home_team_idToTeam.name}{" "}
                  vs{" "}
                  {match.Team_Match_away_team_idToTeam?.Language?.[0]
                    ?.name_th || match.Team_Match_away_team_idToTeam.name}
                </div>
                <div className="livestream-cover__logo">
                  <LogoKub width="50" height="14" alt="SCOREKUB" />
                </div>
              </div>
            )}
          </span>
          <div className="event-livestream-inplay__logo">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="103.121"
              height="18.859"
              viewBox="0 0 103.121 18.859"
            >
              <text
                transform="translate(46.121 14.124)"
                fill="#fff"
                fontSize="13"
                fontFamily="Montserrat-BoldItalic, Montserrat"
                fontWeight="700"
                fontStyle="italic"
              >
                <tspan x="0" y="0">
                  STREAM
                </tspan>
              </text>
              <g>
                <path d="M44 0H6.579L.004 18.859h37.49z" fill="#f33c59"></path>
                <text
                  transform="translate(10.121 12.124)"
                  fill="#fff"
                  fontSize="10"
                  fontFamily="Montserrat-BoldItalic, Montserrat"
                  fontWeight="700"
                  fontStyle="italic"
                >
                  <tspan x="0" y="0">
                    LIVE
                  </tspan>
                </text>
              </g>
            </svg>
          </div>
        </div>
        <div className="event-livestream-inplay__content">
          <div className="event-livestream-inplay__title">...</div>
          <ul className="event-livestream-inplay__list">
            <li>...</li>
            <li>...</li>
          </ul>
          <ol className="livestream-steps-simple">
            <li>
              <span className="go-link cursor-pointer">ลงทะเบียนที่นี่</span>
            </li>
            <li>รับชมถ่ายทอดสดแบบไม่มีโฆษณา!</li>
          </ol>
          <div className="event-livestream-inplay__actions">
            <span className="go-link cursor-pointer btn btn--primary">
              ลงทะเบียนและรับชม
            </span>
          </div>
          <div className="event-livestream-inplay__note">***</div>
        </div>
      </div>
      <div className="player" style={{ display: "none" }}>
        <button className="player1" onClick={handlePlayer1Click}>
          ตัวเล่นหลัก
        </button>
        <button className="player2">ตัวเล่น 2</button>
        <button className="player3">ตัวเล่น 3</button>
      </div>
    </div>
  );
};

export default EventLivestream;
