import React, { useState, useEffect } from "react";
import teamsDefaultImage from "../../../../../assets/images/TeamsDefault.webp";
import { Goal } from "../../../../../assets/images/icons";

const EventHeaderMatchinfo = ({ match }) => {
  const formatDate = (dateStr) => {
    if (!dateStr) return "";
    const date = new Date(dateStr);
    if (!(date instanceof Date) || isNaN(date)) {
      return "Invalid date";
    }
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based in JS
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  const currentTimeStamp = new Date().getTime();
  const matchTimeTimeStamp = new Date(match?.kick_off).getTime();
  const matchTimeDateTime = new Date(matchTimeTimeStamp);
  const [hour, minute] = matchTimeDateTime.toLocaleTimeString().split(":");
  const timeString = `${hour}:${minute}`;

  let displayTime = timeString;

  if (match?.status_id === 2) {
    const minutesPassedFirstHalf = Math.floor(
      (currentTimeStamp - matchTimeTimeStamp) / (60 * 1000) + 1
    );
    if (minutesPassedFirstHalf > 45) {
      const extraMinutesFirstHalf = minutesPassedFirstHalf - 45;
      displayTime = `45+${extraMinutesFirstHalf}'`;
    } else {
      displayTime = `${minutesPassedFirstHalf}'`;
    }
  }
  if (match?.status_id === 3) {
    displayTime = "พักครึ่ง";
  }
  if (match?.status_id === 4) {
    const minutesPassedSecondHalf = Math.floor(
      (currentTimeStamp - matchTimeTimeStamp) / (60 * 1000) + 46
    );
    if (minutesPassedSecondHalf > 90) {
      const extraMinutesSecondHalf = minutesPassedSecondHalf - 90;
      displayTime = `90+${extraMinutesSecondHalf}'`;
    } else {
      displayTime = `${minutesPassedSecondHalf}'`;
    }
  }
  if (match?.status_id === 5) {
    displayTime = "ต่อเวลาพิเศษ";
  }

  const formatShortDate = (dateStr) => {
    if (!dateStr) return "";
    const date = new Date(dateStr);
    if (!(date instanceof Date) || isNaN(date)) {
      return "Invalid date";
    }
    const monthNames = [
      "ม.ค.",
      "ก.พ.",
      "มี.ค.",
      "เม.ย.",
      "พ.ค.",
      "มิ.ย.",
      "ก.ค.",
      "ส.ค.",
      "ก.ย.",
      "ต.ค.",
      "พ.ย.",
      "ธ.ค.",
    ];
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${day} ${month} ${hours}:${minutes}`;
  };

  const [countdown, setCountdown] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const matchDate = new Date(match.match_time);
      const diff = matchDate - now;

      if (diff <= 0) {
        setCountdown("00:00:00");
        clearInterval(interval);
      } else {
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diff % (1000 * 60)) / 1000);

        if (days > 0) {
          setCountdown(
            `${days} days ${hours.toString().padStart(2, "0")}:${minutes
              .toString()
              .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
          );
        } else {
          setCountdown(
            `${hours.toString().padStart(2, "0")}:${minutes
              .toString()
              .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
          );
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [match.match_time]);

  return (
    <div className="event-header-matchinfo">
      <h1 className="event-header-matchinfo__title">
        <a
          href={`/football/teams/${match.Team_Match_home_team_idToTeam.id}`}
          title={
            match.Team_Match_home_team_idToTeam.Language?.name_th ??
            match.Team_Match_home_team_idToTeam.name
          }
          className="team-link event-header-matchinfo__title__team event-header-matchinfo__title__team--home"
        >
          <span>
            {match.Team_Match_home_team_idToTeam.Language?.name_th ??
              match.Team_Match_home_team_idToTeam.name}
          </span>
        </a>
        &nbsp;
        <span className="sr-only">-</span>
        &nbsp;
        <a
          href={`/football/teams/${match.Team_Match_away_team_idToTeam.id}`}
          title={
            match.Team_Match_away_team_idToTeam.Language?.name_th ??
            match.Team_Match_away_team_idToTeam.name
          }
          className="team-link event-header-matchinfo__title__team event-header-matchinfo__title__team--away"
        >
          <span>
            {match.Team_Match_away_team_idToTeam.Language?.name_th ??
              match.Team_Match_away_team_idToTeam.name}
          </span>
        </a>
      </h1>
      <div className="event-header-matchinfo__bar"></div>
      <div className="event-header-team event-header-team--home">
        <div className="event-header-team__body">
          <div className="event-header-team__content">
            <span className="event-header-team__name">
              <span>
                {match.Team_Match_home_team_idToTeam.Language?.name_th ??
                  match.Team_Match_home_team_idToTeam.name}
              </span>
            </span>
            <span className="event-header-team__abbr">
              {match.Team_Match_home_team_idToTeam.short_name ||
                match.Team_Match_home_team_idToTeam.name}
            </span>
          </div>
          <div className="event-header-team__aside">
            <a
              href={`/football/teams/${match.Team_Match_home_team_idToTeam.id}`}
              title={
                match.Team_Match_home_team_idToTeam.Language?.name_th ??
                match.Team_Match_home_team_idToTeam.name
              }
              className="team-link event-header-team__logo"
            >
              <img
                alt={
                  match.Team_Match_home_team_idToTeam.Language?.name_th ??
                  match.Team_Match_home_team_idToTeam.name
                }
                width="60"
                height="60"
                src={
                  match.Team_Match_home_team_idToTeam.logo_original ||
                  teamsDefaultImage
                }
                className=""
              />
            </a>
          </div>
        </div>
        <div className="event-header-team__bar">
          <div className="event-header-team__rank"></div>
        </div>
      </div>
      <div className="event-header-matchinfo__meta">
        <div className="event-header-matchinfo__meta__top">
          {match.status_id === 0 && (
            <span className="event-fulltime-status">ผิดปกติ</span>
          )}
          {match.status_id === 2 && (
            <React.Fragment>
              <span className="event-time-top">
                {displayTime}
                <span className="blinking-apostrophe">&nbsp;</span>
                <span className="event-score-text">
                  <Goal />
                  <span className="">{match?.home_scores[0]}</span>{" "}
                  <span className="event-score-separator">-</span>{" "}
                  <span className="">{match?.away_scores[0]}</span>{" "}
                  <span className="goal-score-animation-text">Goal!</span>
                </span>
              </span>
            </React.Fragment>
          )}
          {match.status_id === 3 && (
            <React.Fragment>
              <span className="event-time-top">
                {displayTime}
                <span className="blinking-apostrophe">&nbsp;</span>
                <span className="event-score-text">
                  <Goal />
                  <span className="">{match?.home_scores[0]}</span>{" "}
                  <span className="event-score-separator">-</span>{" "}
                  <span className="">{match?.away_scores[0]}</span>{" "}
                  <span className="goal-score-animation-text">Goal!</span>
                </span>
                <div className="event-periods-football">
                  <span className="event-periods-football__period">
                    (HT {match?.home_scores[1]} - {match?.away_scores[1]})
                  </span>
                </div>
              </span>
            </React.Fragment>
          )}
          {match.status_id === 4 && (
            <React.Fragment>
              <span className="event-time-top">
                {displayTime}
                <span className="blinking-apostrophe">&nbsp;</span>
                <span className="event-score-text">
                  <Goal />
                  <span className="">{match?.home_scores[0]}</span>{" "}
                  <span className="event-score-separator">-</span>{" "}
                  <span className="">{match?.away_scores[0]}</span>{" "}
                  <span className="goal-score-animation-text">Goal!</span>
                </span>
                <div className="event-periods-football">
                  <span className="event-periods-football__period">
                    (HT {match?.home_scores[1]} - {match?.away_scores[1]})
                  </span>
                </div>
              </span>
            </React.Fragment>
          )}
          {(match.status_id === 5 ||
            match.status_id === 6 ||
            match.status_id === 7) && (
            <span className="event-time-top">
              {displayTime}
              <span className="blinking-apostrophe">&nbsp;</span>
              <span className="event-score-text">
                <Goal />
                <span className="">{match?.home_scores[0]}</span>{" "}
                <span className="event-score-separator">-</span>{" "}
                <span className="">{match?.away_scores[0]}</span>{" "}
                <span className="goal-score-animation-text">Goal!</span>
              </span>
            </span>
          )}

          {/* เงื่อนไขสำหรับแสดงผลตาม status_id */}
          {match.status_id === 0 && (
            <React.Fragment>
              {/* แสดงข้อความ "ผิดปกติ" */}
              <span className="event-fulltime-status">ผิดปกติ</span>
            </React.Fragment>
          )}
          {match.status_id === 8 && (
            <React.Fragment>
              {/* แสดงข้อความ "เสร็จสิ้น" */}
              <span className="event-fulltime-status">เสร็จสิ้น</span>
              <span className="event-score-text">
                <Goal />
                <span className="">{match?.home_scores[0]}</span>{" "}
                <span className="event-score-separator">-</span>{" "}
                <span className="">{match?.away_scores[0]}</span>{" "}
                <span className="goal-score-animation-text">Goal!</span>
              </span>
              <div className="event-periods-football">
                <span className="event-periods-football__period">
                  (HT {match?.home_scores[1]} - {match?.away_scores[1]})
                </span>
              </div>
              <span className="event-start-date">
                {formatDate(match.match_time)}
              </span>
            </React.Fragment>
          )}
        </div>
        {match.status_id === 1 && (
          <React.Fragment>
            {/* ซ่อน event-score-text, event-periods-football, event-time-top */}
            <span className="event-starts-in">{countdown}</span>
            <span className="event-start-date">
              {formatDate(match.match_time)}
            </span>
            <span className="event-start-date-small">
              {formatShortDate(match.match_time)}
            </span>
          </React.Fragment>
        )}
        {/* ... ควรทำต่อเงื่อนไขสำหรับ status_id อื่น ๆ */}

        {/* การแสดงเวลา */}
        {match.status_id !== 2 && match.status_id !== 3 && (
          <React.Fragment>
            {/* <span className="event-starts-in">{countdown}</span>
            <span className="event-start-date">
              {formatDate(match.match_time)}
            </span>
            <span className="event-start-date-small">
              {formatShortDate(match.match_time)}
            </span> */}
          </React.Fragment>
        )}
      </div>
      <div className="event-header-team event-header-team--away">
        <div className="event-header-team__body">
          <div className="event-header-team__content">
            <span className="event-header-team__name">
              <span>
                {match.Team_Match_away_team_idToTeam.Language?.name_th ??
                  match.Team_Match_away_team_idToTeam.name}
              </span>
            </span>
            <span className="event-header-team__abbr">
              {match.Team_Match_away_team_idToTeam.short_name ||
                match.Team_Match_away_team_idToTeam.name}
            </span>
          </div>
          <div className="event-header-team__aside">
            <a
              href={`/football/teams/${match.Team_Match_away_team_idToTeam.id}`}
              title={
                match.Team_Match_away_team_idToTeam.Language?.name_th ??
                match.Team_Match_away_team_idToTeam.name
              }
              className="team-link event-header-team__logo"
            >
              <img
                alt={
                  match.Team_Match_away_team_idToTeam.Language?.name_th ??
                  match.Team_Match_away_team_idToTeam.name
                }
                width="60"
                height="60"
                src={
                  match.Team_Match_away_team_idToTeam.logo_original ||
                  teamsDefaultImage
                }
                className=""
              />
            </a>
          </div>
        </div>
        <div className="event-header-team__bar">
          <div className="event-header-team__rank"></div>
        </div>
      </div>
    </div>
  );
};

export default EventHeaderMatchinfo;
