import React, { useState, useEffect } from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HeaderDesktop from "./components/Header/HeaderDesktop";
import HeaderMobile from "./components/Header/HeaderMobile";
import SubHeader from "./components/SubHeader/SubHeader";
import BreadcrumbBar from "./components/BreadcrumbBar/BreadcrumbBar";
import MainContent from "./components/MainContent/MainContent";
import BottomBar from "./components/BottomBar/BottomBar";
import Match from "./components/Match/Match";
import Popup from "./components/Popup/Popup";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import { osName } from "react-device-detect";
import "./App.css";
import "./AppIcon.css";

function App() {
  useEffect(() => {
    let defaultMode = localStorage.getItem("liveMode") || "WINDOWS_MODE";
    ReactGA.initialize("G-PC8L1Y8DNF");
    ReactGA.pageview(window.location.pathname + window.location.search);
    const tagManagerArgs = {
      gtmId: "GTM-W3G8GJL4",
    };
    TagManager.initialize(tagManagerArgs);

    switch (osName) {
      case "iOS":
        defaultMode = "iOS_MODE";
        break;
      case "Android":
        defaultMode = "ANDROID_MODE";
        break;
      default:
        defaultMode = "WINDOWS_MODE";
        break;
    }

    localStorage.setItem("liveMode", defaultMode);
  }, []);

  const [version, setVersion] = useState(() => {
    const savedVersion = localStorage.getItem("selectedVersion");
    return savedVersion ? savedVersion : "auto"; // auto, mobile, desktop
  });

  const handleVersionChange = (newVersion) => {
    setVersion(newVersion);
    localStorage.setItem("selectedVersion", newVersion);
  };

  const isMobile = () => {
    if (version === "auto") {
      return window.innerWidth <= 768;
    }
    return version === "mobile";
  };

  // เพิ่ม state สำหรับแสดง/ซ่อนป๊อปอัป
  const [showPopup, setShowPopup] = useState(true);

  // ฟังก์ชันเพื่อเปิด/ปิดป๊อปอัป
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <Provider store={store}>
      <Router>
        <div className="app">
          <div className={`wrapper ${isMobile() ? "is-mobile" : "is-desktop"}`}>
            {/* {isMobile() ?  */}
            <HeaderMobile />
            {/* :  */}
            <HeaderDesktop />
            {/* } */}
            <div className="wrapper__inner">
              {!isMobile() && <SubHeader />}
              {!isMobile() && <BreadcrumbBar />}
              {/* {isMobile() ? ( */}
              <Routes>
                <Route
                  path="/football/:TeamName/:matchId"
                  element={<Match />}
                />
                {/* ... สามารถเพิ่ม Route อื่น ๆ ได้ที่นี่ ... */}
                <Route path="/" element={<MainContent />} />
              </Routes>
              {/* ) : ( */}
              {/* <MainContent /> */}
              {/* )} */}
            </div>
            {/* {isMobile() &&  */}
            <BottomBar />
            {/* }*/}
          </div>
          {showPopup && <Popup closePopup={togglePopup} />}
          {/* <div>
            <p>Selected version: {version}</p>

            <button onClick={() => handleVersionChange("mobile")}>
              Mobile
            </button>
            <button onClick={() => handleVersionChange("desktop")}>
              Desktop
            </button>
          </div> */}
        </div>
      </Router>
    </Provider>
  );
}

export default App;
