// HeaderWidgets.js
import React from "react";
import EventHeaderInPlayWidgetsNav from "./EventHeaderInPlayWidgetsNav/EventHeaderInPlayWidgetsNav";
import EventHeaderInPlayWidgetsGridMomentum from "./EventHeaderInPlayWidgetsGridMomentum/EventHeaderInPlayWidgetsGridMomentum";
import EventHeaderLiveStats from "./EventHeaderLiveStats/EventHeaderLiveStats";

const HeaderWidgets = ({ match, onChangeViewMode }) => {
  return (
    <div className="event-header-in-play-widgets">
      <EventHeaderInPlayWidgetsNav onChangeViewMode={onChangeViewMode} />
      <EventHeaderInPlayWidgetsGridMomentum match={match} />
      <EventHeaderLiveStats match={match} />
    </div>
  );
};

export default HeaderWidgets;
