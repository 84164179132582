// redux/reducers/index.js
import { combineReducers } from "redux";
import scoresReducer from "./scoresReducer";
import teamsReducer from "./teamsReducer";
import matchesReducer from "./matchesReducer";
import loadingReducer from "./loadingReducer";
import dateReducer from "./dateReducer";
import liveCountReducer from "./liveCountReducer";
import liveStreamReducer from "./liveStreamReducer";

const rootReducer = combineReducers({
  scores: scoresReducer,
  teams: teamsReducer,
  matches: matchesReducer,
  loading: loadingReducer,
  selectedDate: dateReducer,
  liveCount: liveCountReducer,
  liveStream: liveStreamReducer,
});

export default rootReducer;
