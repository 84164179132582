import { actionTypes } from "../actions";

const initialState = {
  matches: [],
  singleMatch: null,
  date: null,
  error: null,
};

const matchesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MATCHES:
      return {
        ...state,
        matches: action.payload,
        date: action.date,
      };
    case actionTypes.SELECT_DATE:
      return {
        ...state,
        date: action.payload,
      };
    case actionTypes.CLEAR_MATCHES: // Add this case
      return {
        ...state,
        matches: [], // Clear matches
      };
    case actionTypes.SET_SINGLE_MATCH:
      return {
        ...state,
        singleMatch: action.payload,
      };
    case actionTypes.FETCH_MATCH_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default matchesReducer;
