// MatchInfo.js
import React from "react";
import "./MatchInfo.css";
import cloudy from "../../../../assets/images/cloudy.svg";

const MatchInfo = ({ match }) => {
  return (
    <div className="event-section" id="match-facts">
      <div className="match-info">
        <div className="match-info__inner">
          <div className="match-info-item">
            <div className="match-info-item__icon">
              <i className="icon-whistle"></i>
            </div>
            <div className="match-info-item__content">
              <span className="match-info-item__label t-ellipsis">
                ผู้ตัดสิน
              </span>
              <span className="match-info-item__value">
                {match.referee?.name || "-"}
              </span>
            </div>
          </div>
          <div style={{ display: "none" }}></div>
          <div className="match-info-item">
            <div className="match-info-item__icon">
              <i className="icon-stadium"></i>
            </div>
            <div className="match-info-item__content">
              <span className="match-info-item__label t-ellipsis">
                สนามกีฬา
              </span>
              <div style={{ display: "none" }}></div>
              <span className="match-info-item__value">
                {match.venue?.name || "-"}
              </span>
            </div>
          </div>
          <div className="match-info-item">
            <div className="match-info-item__icon">
              <i className="icon-pin"></i>
            </div>
            <div className="match-info-item__content">
              <span className="match-info-item__label t-ellipsis">สถานที่</span>
              <span className="match-info-item__value">
                {match.venue?.city || "-"}
              </span>
            </div>
          </div>
          <div className="match-info-item">
            <div className="match-info-item__icon">
              <i className="icon-umbrella"></i>
            </div>
            <div className="match-info-item__content">
              <span className="match-info-item__label t-ellipsis">
                สภาพอากาศ
              </span>
              <span className="match-info-item__value">
                <img
                  width="18"
                  height="18"
                  alt="Weather Icon"
                  src={cloudy}
                  className="lazyLoad isLoaded"
                />
                {match.environment?.temperature || "-"}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="event-facts" style={{ display: "none" }}>
        <h2 className="event-facts__title">Match</h2>{" "}
        <div className="event-facts__body">
          <ul className="event-facts__list">
            <li></li>
          </ul>{" "}
          <div className="event-facts__actions">
            <button type="button" className="btn-expand color-primary">
              Show more facts
              <i className="icon-down"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchInfo;
