import React, { useState } from "react";

const EventHeaderBar = ({ match }) => {
  const [isListIconVisible, setListIconVisibility] = useState(true);
  return (
    <div className="event-header-bar">
      <div className="event-header-bar__left-side">
        <div className="match-favourite-btn-wrapper">
          <button className="match-favourite-btn is-favourited">
            <i className="match-favourite-btn__icon star outline icon"></i>
          </button>
        </div>
      </div>
      <div className="event-header-bar__breadcrumbs">
        <ul>
          <li>
            <a
              href={`/football/c/${match?.Competition?.Category?.id}`}
              className=""
            >
              {match?.Competition?.Category?.Language?.name_th ??
                match?.Competition?.Category?.name}
            </a>
          </li>
          <li className="t-ellipsis">
            <a href={`/football/league/${match?.Competition?.id}`} className="">
              {match?.Competition?.Language?.name_th ??
                match?.Competition?.name}
              {(match?.round?.round_num !== 0 ||
                match?.round?.group_num !== 0) &&
                " - "}
              {match?.round?.round_num !== 0 &&
                `Round ${match?.round?.round_num}`}
              {match?.round?.group_num !== 0 &&
                `Group ${match?.round?.group_num}`}
            </a>
          </li>
        </ul>
      </div>
      <div className="event-header-bar__right-side">
        <div className="event-dropdown">
          <a
            href="#"
            className="event-dropdown__toggle"
            onClick={(e) => {
              e.preventDefault(); // ป้องกัน behavior ปกติของ <a> tag
              setListIconVisibility(!isListIconVisible); // สลับสถานะของ icon
            }}
          >
            {isListIconVisible ? (
              <i className="list ul icon"></i>
            ) : (
              <i className="close icon"></i>
            )}
          </a>
        </div>
      </div>
    </div>
  );
};

export default EventHeaderBar;
