import React, { useState, useEffect } from "react";
import "./SideBar.css";

function Sidebar() {
  const [leagues, setLeagues] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/popular/football`)
      .then((response) => response.json())
      .then((data) => {
        setLeagues(data.slice(0, 10)); // only the first 10
      });
  }, []);

  const [searchText, setSearchText] = useState("");

  const handleChange = (e) => {
    setSearchText(e.target.value);
  };

  const clearSearch = () => {
    setSearchText("");
  };

  return (
    <aside className="sidebar">
      <div className="search-competitions">
        <div className="search-competitions__form">
          <input
            type="text"
            placeholder="Search League or Event"
            value={searchText}
            onChange={handleChange}
          />
          {searchText ? (
            <i className="x icon" onClick={clearSearch}></i>
          ) : (
            <i className="search icon"></i>
          )}
        </div>
      </div>
      <div className="league-list">
        <div className="league-list__label caption">Popular leagues</div>
        {leagues.map((league) => (
          <div key={league.id} className="league_items">
            <img
              src={league?.logo_original}
              alt={league.name}
              className="league_img"
              width="20"
              height="20"
            />
            <p className="league_name">
              {league?.Language[0]?.name_th ||
                league?.short_name ||
                league.name}
            </p>
          </div>
        ))}
      </div>
    </aside>
  );
}
export default Sidebar;
