// EventTabsSecondary.js
import React from "react";
import "./EventTabsSecondary.css";

const EventTabsSecondary = () => {
  return (
    <ul className="event-tabs-secondary" style={{ display: "none" }}>
      <li>
        <a
          href="#highlights"
          title="Highlights"
          className="event-tabs-secondary__link"
        >
          Highlights
        </a>
      </li>
      <li>
        <a
          href="#who-will-win"
          title="Who will win?"
          className="event-tabs-secondary__link"
        >
          Who will win?
        </a>
      </li>
      <li>
        <a
          href="#match-facts"
          title="Match Facts"
          className="event-tabs-secondary__link"
        >
          Match Facts
        </a>
      </li>
    </ul>
  );
};

export default EventTabsSecondary;
