import { formatDate } from "./dateUtils";
export const actionTypes = {
  SET_MATCHES: "SET_MATCHES",
  SELECT_DATE: "SELECT_DATE",
  SET_SINGLE_MATCH: "SET_SINGLE_MATCH",
  FETCH_MATCH_ERROR: "FETCH_MATCH_ERROR",
  SET_MATCH_LIVE_STREAM: "SET_MATCH_LIVE_STREAM",
  FETCH_MATCH_LIVE_STREAM_ERROR: "FETCH_MATCH_LIVE_STREAM_ERROR",
};

export const setMatches = (matches, date) => ({
  type: actionTypes.SET_MATCHES,
  payload: matches,
  date: date,
});

export const clearMatches = () => {
  return {
    type: "CLEAR_MATCHES",
  };
};

export const fetchMatches = (date, live) => {
  return async (dispatch, getState) => {
    dispatch(clearMatches());
    const formattedDate = formatDate(date);
    let apiUrl;

    apiUrl = `${process.env.REACT_APP_API_URL}/api/matches?lang=th&date=${formattedDate}&tz=07:00`;

    const response = await fetch(apiUrl);
    const data = await response.json();
    //console.log("🚀 ~ file: actions.js:33 ~ return ~ data:", data);
    if (data) {
      const groupedMatches = data.reduce((acc, match) => {
        //const competitionName = match.Competition.id;
        const competitionName = match.competition_id;
        if (!acc[competitionName]) {
          acc[competitionName] = [];
        }
        acc[competitionName].push(match);
        return acc;
      }, {});
      // Dispatch the grouped matches instead of newMatches
      dispatch(setMatches(groupedMatches, date));
    } else {
      console.error("No data received from API");
    }

    dispatch(selectDate(date));
  };
};

export const fetchMatchLive = () => {
  return async (dispatch) => {
    dispatch(clearMatches());

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/match-live-v1`
      );
      const data = await response.json();

      if (data) {
        const groupedMatches = data.reduce((acc, match) => {
          const competitionName = match.competition_id;
          if (!acc[competitionName]) {
            acc[competitionName] = [];
          }
          acc[competitionName].push(match);
          return acc;
        }, {});

        dispatch(setMatches(groupedMatches));
      } else {
        console.error("No data received from API");
      }
    } catch (error) {
      console.error("Error fetching live matches:", error);
    }
  };
};

export const setMatchLiveStream = (matchId) => ({
  type: actionTypes.SET_MATCH_LIVE_STREAM,
  payload: matchId,
});

export const fetchMatchLiveStreamError = (error) => ({
  type: actionTypes.FETCH_MATCH_LIVE_STREAM_ERROR,
  payload: error,
});

export const fetchMatchLiveStream = (matchId) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_STREAM_URL}/api/match/s?matchId=${matchId}`
      );
      const data = await response.json();

      if (data) {
        dispatch(setMatchLiveStream(data));
        //console.log(data);
      } else {
        dispatch(
          fetchMatchLiveStreamError(
            "No data received from API for the given matchId"
          )
        );
      }
    } catch (error) {
      dispatch(fetchMatchLiveStreamError(error.message));
    }
  };
};

export const selectDate = (date) => {
  return {
    type: actionTypes.SELECT_DATE,
    payload: date,
  };
};

export const setSingleMatch = (match) => ({
  type: actionTypes.SET_SINGLE_MATCH,
  payload: match,
});

export const fetchMatchError = (error) => ({
  type: actionTypes.FETCH_MATCH_ERROR,
  payload: error,
});

export const fetchSingleMatch = (matchId) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/match/${matchId}`
      );
      const data = await response.json();

      if (data) {
        dispatch(setSingleMatch(data));
      } else {
        dispatch(
          fetchMatchError("No data received from API for the given matchId")
        );
      }
    } catch (error) {
      dispatch(fetchMatchError(error.message));
    }
  };
};

export const fetchSingleMatchLive = (matchId) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/match-live/${matchId}`
      );
      const data = await response.json();

      if (data) {
        dispatch(setSingleMatch(data));
      } else {
        dispatch(
          fetchMatchError("No data received from API for the given matchId")
        );
      }
    } catch (error) {
      dispatch(fetchMatchError(error.message));
    }
  };
};

export const SET_LIVE_COUNT = "SET_LIVE_COUNT";

export const setLiveCount = (count) => ({
  type: SET_LIVE_COUNT,
  payload: count,
});
