import React, { useState } from "react";
import "./BottomBar.css";

const BottomBar = () => {
  const [activeMenu, setActiveMenu] = useState("/live"); // Initial state

  const menuList = [
    { path: "/live", name: "ถ่ายทอดสด", icon: "youtube icon" },
    { path: "/football", name: "ผลบอลสด", icon: "futbol icon" },
    // { path: "/news", name: "ข่าว", icon: "newspaper icon" },
    // { path: "/tips", name: "ทีเด็ด", icon: "gratipay icon" },
    // { path: "/bookmakers/betting-offers", name: "Bonuses", icon: "icon-gift" },
    // { path: "/other", name: "อื่นๆ", icon: "bars icon" },
  ];

  return (
    <div className="bottom-bar" style={{ display: "none" }}>
      <div id="bottom-bar" className="bottom-bar__nav">
        <ul className="nav-base nav">
          {menuList.map((menu, index) => (
            <li key={index} className="nav__item">
              <a
                href={menu.path}
                aria-current="page"
                className={`nav__link ${
                  activeMenu === menu.path
                    ? "link-exact-active link-active"
                    : ""
                }`}
                onClick={() => setActiveMenu(menu.path)}
              >
                <i className={menu.icon}></i>
                {menu.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
      {/* Rest of your code */}
    </div>
  );
};

export default BottomBar;
