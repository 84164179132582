import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  fetchMatches,
  fetchMatchLive,
  setLiveCount,
} from "../../redux/actions";
import MatchListSubNav from "../ContentWrapper/MatchListSubNav/MatchListSubNav";
import MatchListHead from "../ContentWrapper/MatchListHead/MatchListHead";
import MatchListHeadLine from "../ContentWrapper/MatchListHeadLine/MatchListHeadLine";
import MatchListItem from "../ContentWrapper/MatchListItem/MatchListItem";
import Match from "../Match/Match";
import goalSound from "../../assets/sound/cheers.ogg";
import VarOffside from "../../assets/sound/var-offside.ogg";
import LoadingPage from "../LoadingPage/LoadingPage";
import "./MatchList.css";
import Europe from "../../assets/images/Europe.svg";

const MatchList = ({ matches, fetchMatches, fetchMatchLive }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [hasMore, setHasMore] = useState(true);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [displayedMatches, setDisplayedMatches] = useState({});
  const [live, setLive] = useState(true);
  //const [isToggledByTime, setIsToggledByTime] = useState(true);
  const [liveCount, setLiveCount] = useState(0);
  const [previousScores, setPreviousScores] = useState({});
  const [userInteracted, setUserInteracted] = useState(false);
  const [highlightedMatchIds, setHighlightedMatchIds] = useState([]);

  // 1. ใช้ useState กับ function initializer
  const [isToggledByTime, setIsToggledByTime] = useState(() => {
    // อ่านข้อมูลจาก Local Storage
    const savedValue = localStorage.getItem("SortByTime");
    // ถ้ามีข้อมูล, แปลงข้อมูลจาก string เป็น boolean และ return
    if (savedValue !== null) {
      return JSON.parse(savedValue);
    }
    // ถ้าไม่มีข้อมูล, return true
    return true;
  });

  const handleToggleByTime = (value) => {
    setIsToggledByTime(value);
  };

  // 2. ใช้ useEffect เพื่อเก็บข้อมูลลงใน Local Storage
  useEffect(() => {
    // เก็บข้อมูลลงใน Local Storage
    localStorage.setItem("SortByTime", JSON.stringify(isToggledByTime));
  }, [isToggledByTime]);

  const toggleLive = (isLive) => {
    setLive(isLive);
  };

  const highlightMatch = (match) => {
    setHighlightedMatchIds((prevIds) => [...prevIds, match?.id]);

    setTimeout(() => {
      setHighlightedMatchIds((prevIds) =>
        prevIds.filter((id) => id !== match?.id)
      );
    }, 10000); // 10 วินาที
  };

  const playSound = (soundFile) => {
    if (userInteracted) {
      const audio = new Audio(soundFile);
      audio.play();
    }
  };

  useEffect(() => {
    const handleUserInteraction = () => {
      setUserInteracted(true);
      // ลบ event listener เมื่อมีการโต้ตอบ
      window.removeEventListener("click", handleUserInteraction);
    };

    window.addEventListener("click", handleUserInteraction);

    return () => {
      window.removeEventListener("click", handleUserInteraction);
    };
  }, []);

  const handleUserInteraction = () => {
    setUserInteracted(true);
    window.removeEventListener("click", handleUserInteraction);
  };

  useEffect(() => {
    if (live) {
      fetchMatchLive();
      const intervalId = setInterval(() => {
        fetchMatchLive();
      }, 10000);
      return () => clearInterval(intervalId);
    } else {
      fetchMatches(selectedDate);
    }
  }, [live, selectedDate, fetchMatches, fetchMatchLive]);

  useEffect(() => {
    let updatedMatches = {};
    let liveMatchesCount = 0;
    const liveStatusIds = [2, 3, 4, 5, 7];

    if (live) {
      Object.keys(matches).forEach((key) => {
        updatedMatches[key] = matches[key].filter((match) => {
          if (
            match?.id !== undefined &&
            match?.id !== null &&
            liveStatusIds.includes(match?.status_id)
          ) {
            // ตรวจสอบว่าผลสกอร์มีการเปลี่ยนแปลงหรือไม่
            if (previousScores[match?.id]) {
              if (previousScores[match?.id].home < match?.home_scores[0]) {
                {
                  playSound(goalSound);
                  highlightMatch(match);
                }
              } else if (
                previousScores[match?.id].home > match?.home_scores[0]
              ) {
                {
                  playSound(VarOffside);
                }
              }

              if (previousScores[match?.id].away < match?.away_scores[0]) {
                {
                  highlightMatch(match);
                  playSound(goalSound);
                }
              } else if (
                previousScores[match?.id].away > match?.away_scores[0]
              ) {
                {
                  playSound(VarOffside);
                }
              }
            }

            // อัปเดตผลสกอร์ก่อนหน้า
            setPreviousScores((prev) => ({
              ...prev,
              [match?.id]: {
                home: match?.home_scores[0],
                away: match?.away_scores[0],
              },
            }));
            liveMatchesCount += 1;
            return true;
          }
          return false;
        });
      });
      setLiveCount(liveMatchesCount);
    } else {
      updatedMatches = { ...matches };
    }

    setDisplayedMatches(updatedMatches);
  }, [matches, live]);

  const flattenedMatches = Object.values(displayedMatches).flat();

  const sortedMatches = flattenedMatches.length
    ? isToggledByTime
      ? flattenedMatches.sort((a, b) => {
          return (
            new Date(a?.match_time).getTime() -
            new Date(b?.match_time).getTime()
          );
        })
      : flattenedMatches
    : [];

  const groupedSortedMatches = sortedMatches.reduce((acc, match) => {
    const competitionId = match?.competition_id;
    const matchTimeValue = match?.match_time;

    if (!matchTimeValue) {
      return acc; // ถ้าไม่มี match_time, ข้ามการประมวลผลสำหรับ match นี้
    }

    const matchTime = new Date(matchTimeValue).toISOString();
    const key = `${matchTime}-${competitionId}`;

    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(match);
    return acc;
  }, {});

  return (
    <>
      <MatchListHead />
      <MatchListSubNav
        isToggledByTime={isToggledByTime} // ส่งค่า boolean
        onToggleByTime={handleToggleByTime} // ส่งฟังก์ชัน callback
        toggleLive={toggleLive}
        updateSelectedDate={setSelectedDate}
        selectedDateMatchList={selectedDate}
        liveCount={liveCount}
      />
      <div className="ml__wrap">
        {Object.entries(groupedSortedMatches).map(
          ([key, matchesInCompetition]) => {
            return (
              <div key={key}>
                {matchesInCompetition.length > 0 && (
                  <MatchListHeadLine
                    leagueImage={
                      matchesInCompetition[0]?.Competition?.Country
                        ?.logo_original || Europe
                    }
                    leagueCategory={
                      matchesInCompetition[0]?.Competition?.Category?.Language
                        ?.name_th ||
                      matchesInCompetition[0]?.Competition?.Category?.name
                    }
                    leagueInfo={
                      matchesInCompetition[0]?.Competition?.Language?.name_th ||
                      matchesInCompetition[0]?.Competition?.name
                    }
                  />
                )}
                {matchesInCompetition.map((match) => (
                  <MatchListItem
                    key={match?.id}
                    matchId={match?.id}
                    isLive={live}
                    matchTime={new Date(match?.match_time).getTime()}
                    // matchTime={new Date(match?.match_time).toLocaleTimeString()}
                    homeTeam={[match?.Team_Match_home_team_idToTeam]}
                    awayTeam={[match?.Team_Match_away_team_idToTeam]}
                    homeScores={[match?.home_scores]}
                    awayScores={[match?.away_scores]}
                    liveStream={[match?.liveStream]}
                    matchStatus={[match?.status_id]}
                    matchKickOff={[match?.kick_off]}
                    matchStats={[match?.stats]}
                    matchIncidents={[match?.incidents]}
                    matchLineup={match?.coverage?.lineup}
                    shouldHighlight={highlightedMatchIds.includes(match?.id)}
                    onClick={() => setSelectedMatch(match)}
                  />
                ))}
              </div>
            );
          }
        )}
      </div>
      {selectedMatch && <Match matchDetails={selectedMatch} />}
    </>
  );
};

const mapStateToProps = (state) => ({
  matches: state.matches.matches || {},
});

const mapDispatchToProps = {
  fetchMatches,
  fetchMatchLive,
};

export default connect(mapStateToProps, mapDispatchToProps)(MatchList);
